import { MaintenanceWindow } from '@packages/types/alerts/MaintenanceWindow';

import globalSettings from '@packages/common/constants/globalSettings';

import fetchWrapper from './fetchWrapper';

export default {
  getMetadata() {
    return fetchWrapper('/activity/metadata', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  saveFilter({ groupId, payload }) {
    return fetchWrapper(`/activity/filters/${groupId}`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  testNotification({ groupId, body, type }) {
    const url = `/activity/testNotification/${groupId}`;
    return fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        _t: type,
      }),
    }).then((resp) => resp.json());
  },

  testExistingNotification({ groupId, alertConfigId, notificationId, body, type }) {
    const url = `/activity/testNotification/${groupId}/${alertConfigId}/${notificationId}`;
    return fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        _t: type,
      }),
    }).then((resp) => resp.json());
  },

  loadClusters({ groupId }) {
    return fetchWrapper(`/activity/clusters/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getProjectAlertConfigs({ groupId }) {
    return fetchWrapper(`/activity/alertConfigs/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDeletedProjectAlertConfigs({ groupId }) {
    return fetchWrapper(`/activity/alertConfig/deleted/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getExternalFailureWarnings({ groupId }) {
    return fetchWrapper(`/activity/alertConfigs/externalFailureWarnings/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getAlertConfigHistory({ groupId, alertConfigId }: { groupId: string; alertConfigId: string }) {
    return fetchWrapper(`/activity/alertConfigs/history/${groupId}/${alertConfigId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteProjectAlertConfig({ groupId, alertConfigId }) {
    return fetchWrapper(`/activity/alertConfigs/${groupId}/${alertConfigId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  patchProjectAlertConfig({ groupId, alertConfigId, patchedAlertConfig }) {
    return fetchWrapper(`/activity/alertConfigs/${groupId}/${alertConfigId}`, {
      method: 'PATCH',
      body: JSON.stringify(patchedAlertConfig),
    }).then((resp) => resp.json());
  },

  getMaintenanceWindowConfigs() {
    return fetchWrapper(`/activity/maintenanceWindows/${globalSettings.GROUP_ID}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getActiveOrSoonestMaintenanceWindow() {
    return fetchWrapper(`/activity/maintenanceWindows/activeOrSoonest/${globalSettings.GROUP_ID}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteMaintenanceWindowConfig(id: string) {
    return fetchWrapper(`/activity/maintenanceWindows/${globalSettings.GROUP_ID}/${id}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  createMaintenanceWindowConfig(windowConfig: MaintenanceWindow) {
    return fetchWrapper(`/activity/maintenanceWindows/${globalSettings.GROUP_ID}`, {
      method: 'POST',
      body: JSON.stringify(windowConfig),
    }).then((resp) => resp.json());
  },

  updateMaintenanceWindowConfig(windowConfig: MaintenanceWindow) {
    return fetchWrapper(`/activity/maintenanceWindows/${globalSettings.GROUP_ID}`, {
      method: 'PUT',
      body: JSON.stringify(windowConfig),
    }).then((resp) => resp.json());
  },

  getGroupEvents({ groupId }) {
    return fetchWrapper(`/cloud/activity/group/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getEventTypeMetadata() {
    return fetchWrapper(`/cloud/activity/metadata`, {
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then((result) => result?.eventTypeMetadata);
  },
};
