import { createReducer } from '@packages/redux/common/reducerUtils';

import * as api from '@packages/common/services/api';

const SET_TAGS = 'organization/tags/SET_TAGS';

const initialState = {
  tags: [],
};

const getOrgTagsData = (state, props) => {
  return state.organization[props.orgId].tags || {};
};

export const getTags = (state, props) => getOrgTagsData(state, props).tags || [];

export default createReducer(initialState, {
  [SET_TAGS]: (state, action) => ({ ...state, tags: action.payload }),
});
export const loadTags =
  ({ orgId }) =>
  (dispatch) => {
    return api.organization.getTags({ orgId }).then((response) => {
      const data = response;
      dispatch(
        setTags({
          data,
          orgId,
        })
      );
    });
  };

export const setTags = ({ data, orgId }) => {
  return {
    type: SET_TAGS,
    payload: { data },
    meta: { orgId },
  };
};
