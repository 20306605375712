import { ReadPreference } from '@packages/types/deployment/biConnector';
import { CloudProvider } from '@packages/types/nds/provider';

export type ReadConcernLevel = 'local' | 'majority' | 'linearizable' | 'snapshot' | 'available';
export const READ_CONCERN_LEVELS = ['local', 'majority', 'linearizable', 'snapshot', 'available'];
export const GCP_DATA_FEDERATION_FEATURE_FLAG = 'ATLAS_DATA_FEDERATION_ON_GCP';

export enum TenantState {
  ACTIVE = 'ACTIVE',
  UNVERIFIED = 'UNVERIFIED',
  DELETED = 'DELETED',
}

export interface DataSourceBase {
  storeName: string;
  provider?: DataStoreProvider;
  [key: string]: unknown;
}

export interface S3DataSource extends DataSourceBase {
  provider?: DataStoreProvider.S3;
  path: string;
}

export interface AtlasDataSource extends DataSourceBase {
  provider?: DataStoreProvider.ATLAS;
  database?: string;
  collection?: string;
}

export interface HTTPDataSource extends DataSourceBase {
  provider?: DataStoreProvider.HTTP;
  urls?: Array<string>;
}

export interface DLSDataSourceBase extends DataSourceBase {
  datasetName?: string;
  datasetPrefix?: string;
  // used for empty state when dataset name/prefix has yet
  // to be selected
  pipelineId?: string;
}

export interface DLSAWSDataSource extends DLSDataSourceBase {
  provider?: DataStoreProvider.DLS_AWS;
}

export interface DLSAzureDataSource extends DLSDataSourceBase {
  provider?: DataStoreProvider.DLS_AZURE;
}

export interface DLSGCPDataSource extends DLSDataSourceBase {
  provider?: DataStoreProvider.DLS_GCP;
}

export interface OnlineArchiveDataSource extends DataSourceBase {
  provider?: DataStoreProvider.ONLINE_ARCHIVE;
}

export interface AzureBlobStorageDataSource extends DataSourceBase {
  provider?: DataStoreProvider.AZURE_BLOB_STORAGE;
  path: string;
}

export interface GCSDataSource extends DataSourceBase {
  provider?: DataStoreProvider.GCS;
  path: string;
}

export type DataSource =
  | S3DataSource
  | AtlasDataSource
  | HTTPDataSource
  | DLSAWSDataSource
  | DLSAzureDataSource
  | DLSGCPDataSource
  | OnlineArchiveDataSource
  | AzureBlobStorageDataSource
  | GCSDataSource;

export interface Collection {
  name: string;
  dataSources: Array<DataSource>;
  [key: string]: unknown;
}

export interface View {
  name: string;
  source: string;
  pipeline: string;
  [key: string]: unknown;
}

export interface Database {
  name: string;
  collections: Array<Collection>;
  views: Array<View>;
  [key: string]: unknown;
}

export enum DataStoreProvider {
  S3 = 's3',
  ATLAS = 'atlas',
  HTTP = 'http',
  ONLINE_ARCHIVE = 'online_archive',
  ONLINE_ARCHIVE_DLS = 'online_archive_dls',
  DLS_AWS = 'dls:aws',
  DLS_AZURE = 'dls:azure',
  DLS_GCP = 'dls:gcp',
  AZURE_BLOB_STORAGE = 'azure',
  GCS = 'gcs',
  UNKNOWN = 'unknown',
}

export interface Tag {
  name: string;
  value: string;
}

export interface AtlasDataStoreReadPreference {
  mode: ReadPreference;
  tagSets?: Array<Array<Tag>>;
  maxStalenessSeconds?: number;
  [key: string]: unknown;
}

export interface AtlasDataStoreReadConcern {
  level: ReadConcernLevel;
  [key: string]: unknown;
}

export interface DataStoreBase {
  name: string;
  provider: DataStoreProvider;
  [key: string]: unknown;
}

export interface S3DataStore extends DataStoreBase {
  provider: DataStoreProvider.S3;
  region?: string;
}

export interface AtlasDataStore extends DataStoreBase {
  provider: DataStoreProvider.ATLAS;
  clusterName: string;
  readPreference?: AtlasDataStoreReadPreference;
  readConcern?: AtlasDataStoreReadConcern;
}

export interface HttpDataStore extends DataStoreBase {
  provider: DataStoreProvider.HTTP;
  urls?: Array<string>;
}

export interface DLSDataStoreBase extends DataStoreBase {
  name: string;
}

export interface DLSAWSDataStore extends DLSDataStoreBase {
  provider: DataStoreProvider.DLS_AWS;
}

export interface DLSAzureDataStore extends DLSDataStoreBase {
  provider: DataStoreProvider.DLS_AZURE;
}

export interface DLSGCPDataStore extends DLSDataStoreBase {
  provider: DataStoreProvider.DLS_GCP;
}

export interface AzureBlobStorageDataStore extends DataStoreBase {
  provider: DataStoreProvider.AZURE_BLOB_STORAGE;
  serviceURL: string;
}

export interface GCSDataStore extends DataStoreBase {
  provider: DataStoreProvider.GCS;
}

export type DataStore =
  | S3DataStore
  | AtlasDataStore
  | HttpDataStore
  | DLSAWSDataStore
  | DLSAzureDataStore
  | DLSGCPDataStore
  | AzureBlobStorageDataStore
  | GCSDataStore;

export interface StorageConfigMMS {
  config: StorageConfig;
}

export interface StorageConfig {
  databases: Array<Database>;
  stores: Array<DataStore>;
}

export interface DataProcessRegion {
  cloudProvider: CloudProvider;
  region: string;
}

export enum DataLakeType {
  USER = 'USER',
  ONLINE_ARCHIVE = 'ONLINE_ARCHIVE',
  CHARTS = 'CHARTS',
  ATLAS_SQL = 'ATLAS_SQL',
}

export interface DataLakeTenant {
  groupId: string;
  name: string;
  tenantId: string;
  dataLakeType: DataLakeType;
  createdDate: string;
  lastUpdatedDate: string;
  dataProcessRegion: DataProcessRegion | null;
  clientConfigCount: number;
  clientConfigSource: string;
  clientConfigConfirmed?: boolean;
  tenantConfigAccessed: boolean;
  /** @deprecated */
  queryExecuted: boolean;
  cloudProviderConfig: CloudProviderConfig | undefined;
  hostnames: Array<string>;
  privateLinkHostname: string;
  state: string;
  storage: StorageConfig;
  privateEndpointHostnameMap?: Record<string, string>;
}

export interface DataLakeTenantMap {
  [tenantName: string]: DataLakeTenant;
}

export interface Bucket {
  name: string;
  region?: string;
}

export interface AWSCloudProviderConfig {
  roleId: string | null;
  externalId?: string;
  iamUserARN?: string | null;
  iamAssumedRoleARN?: string | null;
  testS3Bucket: Bucket | null;
}

export interface AzureCloudProviderConfig {
  roleId: string | null;
  servicePrincipalId: string | null;
  tenantId: string | null;
  atlasAppId: string | null;
}

export interface CloudProviderConfigWithAWS {
  aws: AWSCloudProviderConfig;
}

export interface CloudProviderConfigWithAzure {
  azure: AzureCloudProviderConfig;
}

export type CloudProviderConfig = CloudProviderConfigWithAWS | CloudProviderConfigWithAzure;

export interface DataLakeMetrics {
  totalSuccessfulQueries: number;
  totalFailedQueries: number;
  totalDataScanned: number;
  totalDataReturned: number;
  averageExecutionTime: number;
}

export enum ClientConfigSource {
  DATA_LAKE = 'DATA_LAKE',
  ATLAS = 'ATLAS',
}

export interface ValidationResponse {
  errorsCount: number;
  errors: Array<string>;
}

export enum StorageConfigFailedReason {
  JSON_INVALID = 'JSON_INVALID',
  STORAGE_CONFIG_CLIENT_INVALID = 'STORAGE_CONFIG_CLIENT_INVALID',
  STORAGE_CONFIG_DESERIALIZATION_INVALID = 'STORAGE_CONFIG_DESERIALIZATION_INVALID',
  STORAGE_CONFIG_ADL_INVALID = 'STORAGE_CONFIG_ADL_INVALID',
}

export enum StorageConfigWarnReason {
  VISUAL_INCOMPATIBLE = 'VISUAL_INCOMPATIBLE',
  UNKNOWN = 'UNKNOWN',
}

export enum StorageValidationStatus {
  SUCCESS = 'SUCCESS',
  SKIPPED = 'SKIPPED',
  WARN = 'WARN',
  FAIL = 'FAIL',
}

interface BaseValidationResult {
  status: StorageValidationStatus;
}

export interface VisualIncompatibleResult extends BaseValidationResult {
  status: StorageValidationStatus.WARN;
  errorLevel: StorageConfigWarnReason;
  message: string;
  storageConfig: StorageConfig;
}

export interface ADLValidationFailedResult extends BaseValidationResult {
  status: StorageValidationStatus.FAIL;
  errorLevel: StorageConfigFailedReason;
  message: string;
  storageConfig: StorageConfig;
}

export interface ErrorValidationResult extends BaseValidationResult {
  status: StorageValidationStatus.FAIL;
  errorLevel: StorageConfigFailedReason;
  message: string;
}

export interface SuccessValidationResult extends BaseValidationResult {
  status: StorageValidationStatus.SUCCESS;
  storageConfig: StorageConfig;
}

export interface SkippedValidationResult extends BaseValidationResult {
  status: StorageValidationStatus.SKIPPED;
  storageConfig: StorageConfig;
}

export type StorageValidationResult =
  | ErrorValidationResult
  | SuccessValidationResult
  | VisualIncompatibleResult
  | ADLValidationFailedResult
  | SkippedValidationResult;

export enum DataLakeStatus {
  DEFAULT = 'DEFAULT',
  COMPLETE = 'COMPLETE',
  ACTIVE = 'ACTIVE',
}

export enum DatasetType {
  DLS = 'dls',
  ONLINE_ARCHIVE = 'online_archive',
}

export enum SchemaStatus {
  AVAILABLE = 'AVAILABLE',
  EMPTY = 'EMPTY',
}

export enum SchemaUpdatedSource {
  SOURCE_GENERATE_SCHEMA = 'SOURCE_GENERATE_SCHEMA',
  SOURCE_SET_SCHEMA = 'SOURCE_SET_SCHEMA',
  SOURCE_SET_IN_UI = 'SOURCE_SET_IN_UI',
  SOURCE_GENERATED_IN_UI = 'SOURCE_GENERATED_IN_UI',
  SOURCE_SCHEDULED_UPDATE = 'SOURCE_SCHEDULED_UPDATE',
  SOURCE_UNSPECIFIED = 'SOURCE_UNSPECIFIED',
}

export interface Schema {
  version: string;
  jsonSchema: string;
}

export interface Namespace {
  db: String;
  collection: String;
}

export interface SchemaWithMetadata {
  schema?: Schema;
  namespace: Namespace;
  source: SchemaUpdatedSource;
  status: SchemaStatus;
  lastUpdate?: LastUpdate;
}

export interface LastUpdate {
  lastUpdatedTime: string;
  lastUpdatedBy: string;
  lastFailureTime?: string;
  lastFailureDetails?: string;
}

export interface TenantSQLData {
  schemas: Array<SchemaWithMetadata>;
  totalSchemas: Number;
  hasSchemas: boolean;
}

export interface DataLakeSQLSchemaManagement {
  tenantSQLData: TenantSQLData;
  schedule: TenantSQLScheduledUpdate;
}

export enum Frequency {
  FREQUENCY_UNSPECIFIED = 'FREQUENCY_UNSPECIFIED',
  FREQUENCY_DAILY = 'FREQUENCY_DAILY',
  FREQUENCY_WEEKLY = 'FREQUENCY_WEEKLY',
  FREQUENCY_MONTHLY = 'FREQUENCY_MONTHLY',
}

export interface TenantSQLScheduledUpdate {
  frequency: Frequency;
}

export const FrequencyMapping = {
  [Frequency.FREQUENCY_UNSPECIFIED]: 'No schedule',
  [Frequency.FREQUENCY_DAILY]: 'Daily',
  [Frequency.FREQUENCY_WEEKLY]: 'Weekly',
  [Frequency.FREQUENCY_MONTHLY]: 'Monthly',
};

export const SQLSchemaManagementTableHeaders = {
  NAMESPACE: 'Namespace',
  SCHEMA_STATUS: 'Schema Status',
  LAST_UPDATED_TIME: 'Last Updated',
  LAST_UPDATED_BY: 'Last Updated by',
  SCHEMA_ACTIONS: 'Schema Actions',
};

export const SQLSchemaManagementTableHeadersValues = Object.values(SQLSchemaManagementTableHeaders);
