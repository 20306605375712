import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';
import { type RequestParams } from '@packages/types/RequestParams';

import * as api from '@packages/common/services/api';
import * as oktaUtils from '@packages/common/utils/oktaUtils';
import { justLoggedInRedirect } from '@packages/common/utils/authRedirectUtils';

interface ProcessAuthParams {
  requestParams: RequestParams;
  location: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
  oktaSessionExists?: () => Promise<boolean>;
  endOktaSession?: () => void;
}

export default async function processAuth({
  requestParams,
  location,
  windowLocation = window.location,
  oktaSessionExists = oktaUtils.oktaSessionExists,
  endOktaSession = oktaUtils.endOktaSession,
}: ProcessAuthParams) {
  const parsedSearchLocation = queryString.parse(location.search) as Search;
  const {
    uId,
    email,
    csrfToken,
    csrfTime,
    needsMfa,
    username,
    sessionToken,
    idp,
    inviteToken: inviteTokenParam,
    personalizationWizardRedirect,
  } = parsedSearchLocation;

  if (uId) {
    justLoggedInRedirect({
      username,
      email,
      csrfTime,
      csrfToken,
      baasCentralUrl: requestParams.baasCentralUrl,
      baasLegacyUrl: requestParams.baasLegacyCentralUrl,
      centralUrl: requestParams.centralUrl,
      needsMfa: needsMfa === 'true',
      query: parsedSearchLocation,
      windowLocation,
      segmentDefaultUserId: requestParams.segmentDefaultUserId,
      personalizationWizardRedirect,
    });
  } else if (sessionToken) {
    const sessionExists = await oktaSessionExists();
    if (sessionExists) {
      await endOktaSession();
    }
    const clientState = parsedSearchLocation;
    delete clientState.sessionToken;
    const { loginRedirect } = await api.auth.startOidc({ sessionToken, clientState });
    windowLocation.assign(loginRedirect);
  } else if (idp) {
    if (inviteTokenParam) {
      // This case occurs when trying to login to accept an invite for a federated user.
      // We need to make sure they don't pickup an existing Okta session
      const sessionExists = await oktaSessionExists();
      if (sessionExists) {
        await endOktaSession();
      }
    }
    const clientState = parsedSearchLocation;
    delete clientState.idp;
    const { loginRedirect } = await api.auth.startOidc({ idpId: idp, clientState });
    windowLocation.assign(loginRedirect);
  } else {
    // This case shouldn't occur unless someone has navigated directly to this page,
    // in which case just go back to the LoginPage if they don't have an Okta session
    const sessionExists = await oktaSessionExists();
    if (sessionExists) {
      await api.auth.startOidc({ clientState: parsedSearchLocation });
    } else {
      windowLocation.assign('/');
    }
  }
}
