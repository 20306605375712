import { Exception, SeverityLevel as SentrySeverityLevel } from '@sentry/types';

import { StrictUnion } from './utilTypes';

export enum Application {
  Admin = 'Admin',
  Federation = 'Federation',
  Organization = 'Organization',
  Project = 'Project',
  Account = 'Account',
  Auth = 'Auth',
  Clusters = 'Clusters',
  Ecosystem = 'Ecosystem',
  Preferences = 'Preferences',
}

export enum CloudTeams {
  'AlertsPlatform' = 'Alerts Platform',
  'AppServices' = 'App Services',
  'AtlasI' = 'Atlas I',
  'AtlasIi' = 'Atlas II',
  'AtlasIii' = 'Atlas III',
  'AtlasGrowth' = 'Atlas Growth',
  'AtlasInternTeam' = 'Atlas Intern Team',
  'AtlasMigration' = 'Atlas Migration',
  'AtlasStreams' = 'Atlas Streams',
  'AtlasTriage' = 'Atlas Triage',
  'AtlasExtendedI' = 'Atlas Extended I',
  'AtlasServerless' = 'Atlas Serverless',
  'AtlasOnlineArchiveII' = 'Atlas Online Archive II',
  'AtlasXV' = 'Atlas XV',
  'Automation' = 'Automation',
  'BaaS' = 'BAAS',
  'BackupAtlas' = 'Backup - Atlas',
  'BackupPrivateCloud' = 'Backup - Private Cloud',
  'CoreBillingAndIntegrations' = 'Core Billing and Integrations',
  'CoreIam' = 'Core IAM',
  'Design' = 'Design',
  'DeveloperProductivity' = 'Developer Productivity',
  'EngineeringManagement' = 'Engineering Management',
  'FrontEndPlatform' = 'Front End Platform',
  'InsightsAndTelemetry' = 'Insights and Telemetry',
  'OpsManager' = 'Ops Manager',
  'Kubernetes' = 'Kubernetes',
  'Payments' = 'Payments',
  'ProgramManagement' = 'Program Management',
  'Search' = 'Search',
  'WebSearch' = 'WebSearch',
  'Security' = 'Security',
  'Sre' = 'SRE',
  'Triage' = 'Triage',
}

export interface ErrorObject {
  errorCode: string;
  message?: string;
}

export type ErrorMaybe = StrictUnion<
  | ErrorObject
  | Error
  | {
      error?: Error;
      requestUrl?: string;
      errorCode?: string;
      statusCode?: number;
    }
  | Exception
>;

export enum ErrorSeverity {
  FATAL = 'fatal',
  ERROR = 'error',
  WARNING = 'warning',
  LOG = 'log',
  INFO = 'info',
  DEBUG = 'debug',
}

export type ErrorSeverityLevel = SentrySeverityLevel;
