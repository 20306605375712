export enum OrgRole {
  ORG_OWNER = 'ORG_OWNER',
  ORG_GROUP_CREATOR = 'ORG_GROUP_CREATOR',
  ORG_BILLING_ADMIN = 'ORG_BILLING_ADMIN',
  ORG_BILLING_READ_ONLY = 'ORG_BILLING_READ_ONLY',
  ORG_READ_ONLY = 'ORG_READ_ONLY',
  ORG_MEMBER = 'ORG_MEMBER',
}

export enum GroupRole {
  GROUP_OWNER = 'GROUP_OWNER',
  GROUP_AUTOMATION_ADMIN = 'GROUP_AUTOMATION_ADMIN',
  GROUP_BACKUP_ADMIN = 'GROUP_BACKUP_ADMIN',
  GROUP_MONITORING_ADMIN = 'GROUP_MONITORING_ADMIN',
  GROUP_USER_ADMIN = 'GROUP_USER_ADMIN',
  GROUP_CLUSTER_MANAGER = 'GROUP_CLUSTER_MANAGER',
  GROUP_DATA_ACCESS_ADMIN = 'GROUP_DATA_ACCESS_ADMIN',
  GROUP_DATA_ACCESS_READ_WRITE = 'GROUP_DATA_ACCESS_READ_WRITE',
  GROUP_DATA_ACCESS_READ_ONLY = 'GROUP_DATA_ACCESS_READ_ONLY',
  GROUP_READ_ONLY = 'GROUP_READ_ONLY',
  GROUP_SEARCH_INDEX_EDITOR = 'GROUP_SEARCH_INDEX_EDITOR',
  GROUP_STREAM_PROCESSING_OWNER = 'GROUP_STREAM_PROCESSING_OWNER',
}

export enum GlobalRole {
  GLOBAL_AUTOMATION_ADMIN = 'GLOBAL_AUTOMATION_ADMIN',
  GLOBAL_BACKUP_ADMIN = 'GLOBAL_BACKUP_ADMIN',
  GLOBAL_METERING_USER = 'GLOBAL_METERING_USER',
  GLOBAL_METRICS_INTERNAL_USER = 'GLOBAL_METRICS_INTERNAL_USER',
  GLOBAL_SERVERLESS_LIVENESS_ADMIN = 'GLOBAL_SERVERLESS_LIVENESS_ADMIN',
  GLOBAL_MONITORING_ADMIN = 'GLOBAL_MONITORING_ADMIN',
  GLOBAL_OWNER = 'GLOBAL_OWNER',
  GLOBAL_READ_ONLY = 'GLOBAL_READ_ONLY',
  GLOBAL_ADMIN_READ_ONLY = 'GLOBAL_ADMIN_READ_ONLY',
  GLOBAL_ORG_READ_ONLY = 'GLOBAL_ORG_READ_ONLY',
  GLOBAL_USER_ADMIN = 'GLOBAL_USER_ADMIN',
  GLOBAL_USER_READ_ONLY = 'GLOBAL_USER_READ_ONLY',
  GLOBAL_ACCOUNT_SUSPENSION_ADMIN = 'GLOBAL_ACCOUNT_SUSPENSION_ADMIN',
  GLOBAL_BILLING_ADMIN = 'GLOBAL_BILLING_ADMIN',
  GLOBAL_BILLING_READ_ONLY = 'GLOBAL_BILLING_READ_ONLY',
  GLOBAL_BILLING_FEATURE_TEAM_USER = 'GLOBAL_BILLING_FEATURE_TEAM_USER',
  GLOBAL_METERING_FEATURE_TEAM_USER = 'GLOBAL_METERING_FEATURE_TEAM_USER',
  GLOBAL_LEGAL_ADMIN = 'GLOBAL_LEGAL_ADMIN',
  GLOBAL_FEATURE_FLAG_ADMIN = 'GLOBAL_FEATURE_FLAG_ADMIN',
  GLOBAL_APP_SETTING_ADMIN = 'GLOBAL_APP_SETTING_ADMIN',
  GLOBAL_CRON_JOBS_ADMIN = 'GLOBAL_CRON_JOBS_ADMIN',
  GLOBAL_ATLAS_TSE = 'GLOBAL_ATLAS_TSE',
  GLOBAL_ATLAS_OPERATOR = 'GLOBAL_ATLAS_OPERATOR',
  GLOBAL_ATLAS_ADMIN = 'GLOBAL_ATLAS_ADMIN',
  GLOBAL_EVENT_ADMIN = 'GLOBAL_EVENT_ADMIN',
  GLOBAL_BAAS_ADMIN = 'GLOBAL_BAAS_ADMIN',
  GLOBAL_CHARTS_ADMIN = 'GLOBAL_CHARTS_ADMIN',
  GLOBAL_BAAS_INTERNAL_ADMIN = 'GLOBAL_BAAS_INTERNAL_ADMIN',
  GLOBAL_CCS_INTERNAL_ADMIN = 'GLOBAL_CCS_INTERNAL_ADMIN',
  GLOBAL_SECURITY_ADMIN = 'GLOBAL_SECURITY_ADMIN',
  GLOBAL_QUERY_ENGINE_INTERNAL_ADMIN = 'GLOBAL_QUERY_ENGINE_INTERNAL_ADMIN',
  GLOBAL_PROACTIVE_SUPPORT_ADMIN = 'GLOBAL_PROACTIVE_SUPPORT_ADMIN',
  GLOBAL_INFRASTRUCTURE_INTERNAL_ADMIN = 'GLOBAL_INFRASTRUCTURE_INTERNAL_ADMIN',
  GLOBAL_SALESFORCE_ADMIN = 'GLOBAL_SALESFORCE_ADMIN',
  GLOBAL_SALESFORCE_READ_ONLY = 'GLOBAL_SALESFORCE_READ_ONLY',
  GLOBAL_EXPERIMENT_ASSIGNMENT_ADMIN = 'GLOBAL_EXPERIMENT_ASSIGNMENT_ADMIN',
  GLOBAL_EXPERIMENT_OVERRIDE_ASSIGNMENT_ADMIN = 'GLOBAL_EXPERIMENT_OVERRIDE_ASSIGNMENT_ADMIN',
  GLOBAL_ATLAS_SOFTWARE_VERSION_ROLLOUT_ADMIN = 'GLOBAL_ATLAS_SOFTWARE_VERSION_ROLLOUT_ADMIN',
  GLOBAL_PARTNER_ADMIN = 'GLOBAL_PARTNER_ADMIN',
  GLOBAL_CRASH_LOG_ANALYST = 'GLOBAL_CRASH_LOG_ANALYST',
  GLOBAL_BAAS_FEATURE_ADMIN = 'GLOBAL_BAAS_FEATURE_ADMIN',
  GLOBAL_BAAS_SUPPORT = 'GLOBAL_BAAS_SUPPORT',
  GLOBAL_UNIFORM_FRONTEND_ENVOY = 'GLOBAL_UNIFORM_FRONTEND_ENVOY',
  GLOBAL_BAAS_X509_CERTIFICATE = 'GLOBAL_BAAS_X509_CERTIFICATE',
}

export type Role = OrgRole | GroupRole | GlobalRole;

export interface RoleAssignment {
  role: Role;
  groupId?: string;
  orgId?: string;
}

export interface Permission<T extends Role> {
  value: T;
  label: string;
  description: string;
  ndsOnly?: boolean;
}

export interface GroupPermission extends Permission<GroupRole> {
  isAtlas?: boolean;
}
