import { Organization, SetGroupFlagParams } from '@packages/types/settings';
import { ThemePreference } from '@packages/types/theme';

import fetchWrapper, { formParams } from './fetchWrapper';

export default {
  addTempApiKey(description, centralUrl = '') {
    return fetchWrapper(`${centralUrl}/settings/addTempApiKey`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      credentials: 'include',
      body: formParams({
        desc: description,
      }),
    }).then((resp) => resp.json());
  },

  systemHealth() {
    return fetchWrapper('/system/health', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  activeUserOrganizations(centralUrl = '') {
    return fetchWrapper(`${centralUrl}/settings/orgs`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  activeUserOrganizationsWithEmbargoedOrgs(): Promise<{ orgs: Array<Organization> }> {
    return fetchWrapper('/settings/orgsWithEmbargoedOrgs', {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  updateTimeZone({ timeZoneId, displayName }: any = {}) {
    const params = {
      timeZoneId,
      displayName,
    };
    return fetchWrapper('/settings/updateTimeZoneId', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  updateBillingEmail({ orgId, emailAddress }) {
    return fetchWrapper(`/settings/updateBillingEmail/${orgId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        emailAddress,
      }),
    }).then((resp) => resp.json());
  },

  updateDateFormat(value) {
    return fetchWrapper('/settings/updateDateFormat', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        value,
      }),
    }).then((resp) => resp.json());
  },

  updateTimeFormat(value) {
    return fetchWrapper('/settings/updateTimeFormat', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        value,
      }),
    }).then((resp) => resp.json());
  },

  updateAtlasGoToSettings({ isDisabled }) {
    return fetchWrapper('/settings/updateAtlasGoToSettings', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        isDisabled,
      }),
    }).then((resp) => resp.json());
  },

  updateFlaggedForPersonalizationWizard({ enabled }) {
    return fetchWrapper('/settings/updateFlaggedForPersonalizationWizard', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        enabled,
      }),
    }).then((resp) => resp.json());
  },

  updateThemePreference(value: ThemePreference): Promise<unknown> {
    return fetchWrapper('/settings/updateThemePreference', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        value,
      }),
    });
  },

  acknowledgeDarkMode(): Promise<unknown> {
    return fetchWrapper('/settings/acknowledgeDarkMode', {
      method: 'POST',
    });
  },

  getGroupUsers(groupId) {
    return fetchWrapper(`/settings/group/users/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAtlasOperatorControlledFeatureFlags(groupId) {
    return fetchWrapper(`/admin/nds/groups/${groupId}/operatorFeatureFlags`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getGroupControlledFeatureFlags(groupId) {
    return fetchWrapper(`/settings/featureFlags/groups/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getOrgControlledFeatureFlags(orgId) {
    return fetchWrapper(`/settings/featureFlags/orgs/${orgId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  toggleExtendedStorageSizesEnabled({ groupId, enabled }) {
    const url = `/settings/updateExtendedStorageSizesEnabled/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ value: enabled }),
    }).then((resp) => resp.json());
  },

  toggleEditableFeatureFlag({
    groupId,
    featureFlag,
    enabled,
  }: {
    groupId: string;
    featureFlag: string;
    enabled: boolean;
  }) {
    return fetchWrapper(`/settings/editableFeatureFlag/${groupId}/${featureFlag}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        value: enabled,
      }),
    }).then((resp) => resp.json());
  },

  toggleAtlasOperatorFeatureFlag({ groupId, featureFlag, enabled }) {
    return fetchWrapper(`/admin/nds/groups/${groupId}/operatorFeatureFlags/${featureFlag}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        value: enabled,
      }),
    }).then((resp) => resp.json());
  },

  toggleGroupFeatureFlag({ groupId, featureFlag, enabled }) {
    return fetchWrapper(`/settings/featureFlag/${groupId}/${featureFlag}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        value: enabled,
      }),
    }).then((resp) => resp.json());
  },

  toggleOrgFeatureFlag({ orgId, featureFlag, enabled }) {
    return fetchWrapper(`/settings/orgfeatureFlag/${orgId}/${featureFlag}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        value: enabled,
      }),
    }).then((resp) => resp.json());
  },

  setOpsGenieIntegration({ groupId, body }) {
    const url = `/settings/updateOpsGenie/${groupId}`;
    return fetchWrapper(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  setEnableCurrentIpWarning({ groupId, isEnabled }: SetGroupFlagParams) {
    const url = `/settings/setEnableCurrentIpWarning/${groupId}`;
    const body = formParams({
      isEnabled,
    });
    return fetchWrapper(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });
  },

  setIsProjectOverviewEnabled({ groupId, isEnabled }: SetGroupFlagParams) {
    const url = `/settings/isProjectOverviewEnabled/${groupId}`;
    const body = formParams({
      isEnabled,
    });
    return fetchWrapper(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });
  },
};
