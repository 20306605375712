import _ from 'underscore';

import {
  ClusterDescription,
  ClusterDescriptionModel,
  InternalClusterRole,
} from '@packages/types/nds/clusterDescription';
import { ClusterOutageSimulation } from '@packages/types/nds/clusterOutageSimulation';
import { LoadSampleDatasetStatus } from '@packages/types/nds/loadSampleDataset';
import { CloudProvider, Instance, InstanceSize, InstanceSizes } from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';
import { AutoIndex, ReplicationSpec, ReplicationSpecList } from '@packages/types/nds/replicationSpec';
import { SearchInstanceClass, SearchInstanceSize } from '@packages/types/search/decoupled/deployment';

import { VALID_NDS_INSTANCE_NAME_REGEX } from '@packages/common/constants/nds';
import { ProviderOptionsModel } from '@packages/common/models/ProviderOptions';
import { CLUSTER_TYPE } from '@packages/common/schemas/ndsClusterForm';
import { isClusterNameUnused } from '@packages/common/utils/isNameUnused';
import { findLoadSampleDatasetStatusForCluster } from '@packages/common/utils/loadSampleDataset';
import replicationSpecListUtils from '@packages/common/utils/replicationSpecList';

interface CanLoadSampleDatasetArgs {
  clusterDetail: any;
  loadSampleDatasetStatus?: LoadSampleDatasetStatus;
  loadSampleDatasetStatusCollection?: Array<LoadSampleDatasetStatus>;
}

const MIN_FREE_PERCENTAGE_DISKSPACE_WITH_OPLOG = 0.25;
// See AutomationDiffValidationSvc.java#L1050 for 3.6+ 990MB oplogSizeMB requirement.
const MIN_OPLOG_SIZE_MB_360_PLUS = 990;
const MAX_DEFAULT_OPLOG_SIZE_MB = 50 * 1024;

const DEFAULT_OPLOG_SIZE_PERCENT = 0.05;
const NVME_OPLOG_SIZE_PERCENT = 0.1;
const CLUSTER_NAME_UNIQUE_PREFIX_LENGTH = 23;

const NVME_MIN_OPLOG_WINDOW = 3;

function _getProviderCounts(clusterDescriptions, providers) {
  return clusterDescriptions.reduce((accum, curr) => {
    const preferredProvider = replicationSpecListUtils.getMostCommonPreferredBackingCloudProvider(
      curr.replicationSpecList
    );
    accum[preferredProvider]++;
    return accum;
  }, providers);
}

export const MAX_PAUSED_DAYS = 30;

export const MINIMUM_IO1_IOPS = 100;

export enum NodeTypeFamily {
  BASE = 'BASE',
  ANALYTICS = 'ANALYTICS',
}

export const DEFAULT_NODE_TYPE_SET = new Set<NodeTypeFamily>([NodeTypeFamily.BASE, NodeTypeFamily.ANALYTICS]);

export function isNameValid(name) {
  return name !== '' && VALID_NDS_INSTANCE_NAME_REGEX.test(name);
}

export function isNamePrefixUnique(name, clusters) {
  return !clusters.find(
    (clusterName) =>
      clusterName.substring(0, CLUSTER_NAME_UNIQUE_PREFIX_LENGTH) ===
      name.substring(0, CLUSTER_NAME_UNIQUE_PREFIX_LENGTH)
  );
}

export function isNameEndWithHyphen(name) {
  return name.endsWith('-') || name.charAt(CLUSTER_NAME_UNIQUE_PREFIX_LENGTH - 1) === '-';
}

export function getDefaultProvider(clusterDescriptions: Array<ClusterDescription>, isPrivateIpModeEnabled = false) {
  // the following works for 'regular' as well as tenant clusters:
  // check the preferred region of a cluster's replication spec;
  // default to the most often-preferred provider
  if (clusterDescriptions.length === 0) return isPrivateIpModeEnabled ? 'GCP' : 'AWS';

  let providerCounts;
  if (isPrivateIpModeEnabled) {
    providerCounts = _getProviderCounts(clusterDescriptions, { GCP: 0, AZURE: 0 });
  } else {
    providerCounts = _getProviderCounts(clusterDescriptions, { AWS: 0, GCP: 0, AZURE: 0 });
  }

  return _.max(Object.keys(providerCounts), (p) => providerCounts[p]);
}

export function hasFreeCluster(clusterDescriptions: Array<ClusterDescription>): boolean {
  return clusterDescriptions.some(
    (cd) => replicationSpecListUtils.getFirstInstanceSize(cd.replicationSpecList) === InstanceSizes.M0
  );
}

export function hasPaidCluster(clusterDescriptions: Array<ClusterDescription>): boolean {
  return clusterDescriptions.some(
    (cd) => replicationSpecListUtils.getFirstInstanceSize(cd.replicationSpecList) !== InstanceSizes.M0
  );
}

export function isShardedCluster(clusterType): boolean {
  return clusterType === CLUSTER_TYPE.SHARDED || clusterType === CLUSTER_TYPE.GEOSHARDED;
}

export function isGeoShardedCluster(clusterType): boolean {
  return clusterType === CLUSTER_TYPE.GEOSHARDED;
}

export function isFreeCluster(instanceSize: string): boolean {
  return instanceSize === 'M0';
}

export function isFreeOrSharedTierCluster(instanceSize: string | undefined): boolean {
  return instanceSize ? ['M0', 'M2', 'M5'].indexOf(instanceSize) !== -1 : false;
}

export function isServerlessCluster(instanceSize: string): boolean {
  return instanceSize.indexOf('SERVERLESS') != -1;
}

export function isDedicatedCluster(instanceSize: string): boolean {
  return !isFreeOrSharedTierCluster(instanceSize) && !isServerlessCluster(instanceSize);
}

export function isMTM(clusterDescription: ClusterDescription): boolean {
  return (
    !!clusterDescription.internalClusterRole &&
    [InternalClusterRole.SHARED_MTM, InternalClusterRole.SERVERLESS_MTM, InternalClusterRole.USS_MTM].indexOf(
      clusterDescription.internalClusterRole
    ) >= 0
  );
}

export function hasDedicatedCluster(clusterDescriptions: Array<ClusterDescription>): boolean {
  return clusterDescriptions.some(
    (cd) =>
      cd.replicationSpecList &&
      isDedicatedCluster(replicationSpecListUtils.getFirstInstanceSize(cd.replicationSpecList))
  );
}

export function isPausedDedicated(clusterDescription): boolean {
  return (
    clusterDescription.isPaused() &&
    !replicationSpecListUtils
      .getCloudProviders(clusterDescription.getReplicationSpecList())
      .includes(CloudProvider.FREE)
  );
}

export function hasSharedCluster(clusterDescriptions: Array<ClusterDescription>): boolean {
  return clusterDescriptions.some((cd) =>
    isFreeOrSharedTierCluster(replicationSpecListUtils.getFirstInstanceSize(cd.replicationSpecList))
  );
}

export function getInstanceSizeDisplay(size: string): string {
  return (
    size &&
    size
      .replace(/_NVME$/, '')
      .replace(/_HIGHCPU$/, '')
      .replace(/^R/, 'M')
      .replace(/^M0$/, 'M0 Sandbox')
  );
}

export function getSearchInstanceDetailsDisplay(searchInstanceSize: SearchInstanceSize): string {
  return `${searchInstanceSize.ramSizeGB} GB RAM, ${searchInstanceSize.storageGB} GB Storage`;
}

export function getSearchInstanceClassDisplay(searchInstanceClass: SearchInstanceClass): string {
  switch (searchInstanceClass) {
    case SearchInstanceClass.HIGH_CPU:
      return 'High-CPU';
    case SearchInstanceClass.LOW_CPU:
      return 'Low-CPU';
  }
}

export function getNodeTypeFamilySet(hasAnalyticsNodes: boolean, isAnalyticsTier: boolean): Set<NodeTypeFamily> {
  if (!hasAnalyticsNodes) {
    return new Set<NodeTypeFamily>().add(NodeTypeFamily.BASE).add(NodeTypeFamily.ANALYTICS);
  } else if (isAnalyticsTier) {
    return new Set<NodeTypeFamily>().add(NodeTypeFamily.ANALYTICS);
  } else {
    return new Set<NodeTypeFamily>().add(NodeTypeFamily.BASE);
  }
}

export function getInstanceSizeDisplayWithClass(size, isLowCPU, isNVMe) {
  const instanceSize = getInstanceSizeDisplay(size);
  let instanceClass;
  if (isLowCPU) {
    instanceClass = 'Low CPU';
  } else if (isNVMe) {
    instanceClass = 'NVMe SSD';
  } else {
    instanceClass = 'General';
  }
  return `${instanceSize} (${instanceClass})`;
}

// The preferred method is getInstanceSizeDisplayWithClass. This method should only be used if it is
// impossible to translate the instance size name to the instance size object which has variables
// isLowCPU and isNVMe.
export function getInstanceSizeDisplayWithClassStringParsing(instanceSizeName: String) {
  const isLowCPU: boolean = instanceSizeName && instanceSizeName.startsWith('R');
  const isNVMe: boolean = instanceSizeName && instanceSizeName.includes('NVME');
  return getInstanceSizeDisplayWithClass(instanceSizeName, isLowCPU, isNVMe);
}

export function formatDiskSizeString(diskSizeGB) {
  if (diskSizeGB < 1) {
    return `${diskSizeGB * 1024} MB`;
  }
  return `${diskSizeGB} GB`;
}

export function shouldDisableActions(clusterDescription: ClusterDescriptionModel): boolean {
  return (
    clusterDescription.isDeleting() ||
    clusterDescription.isCreating() ||
    clusterDescription.isPaused() ||
    clusterDescription.isUnderCompaction() ||
    clusterDescription.isTenantUpgrading() ||
    clusterDescription.isDecomposedShardedCluster()
  );
}

export function isDiskBackupAllowed(providers) {
  return providers.some((provider) => provider === 'AZURE' || provider === 'AWS' || provider === 'GCP');
}

export function getMinProvisionedIOPS(instanceSize, diskSizeGB) {
  const minIOPSForInstance = instanceSize.minIOPS;
  const minIOPSPerGB = instanceSize.minIOPSPerGB;
  const minIOPS = Math.max(minIOPSForInstance, minIOPSPerGB * diskSizeGB);

  const maxIOPSForInstance = instanceSize.maxIOPS || instanceSize.maxStandardIOPS;
  return Math.min(minIOPS, maxIOPSForInstance);
}

export function getMaxReadIOPS(instance, diskSizeGB) {
  const { maxReadIOPS, readIopsPerGB } = instance;
  return Math.min(readIopsPerGB * diskSizeGB, maxReadIOPS);
}

export function getMaxWriteIOPS(instance, diskSizeGB) {
  const { maxWriteIOPS, writeIopsPerGB } = instance;
  return Math.min(writeIopsPerGB * diskSizeGB, maxWriteIOPS);
}

export function getStandardIOPS(instanceSize: Instance, diskSizeGB: number) {
  const minProvisionedIOPS = getMinProvisionedIOPS(instanceSize, diskSizeGB);
  const maxStandardIOPSForInstance = instanceSize.maxStandardIOPS!;

  return Math.min(minProvisionedIOPS, maxStandardIOPSForInstance);
}

export function getStandardIOPSForGP3(instance: Instance, diskSizeGB: number) {
  const minIOPSForInstance = 3000;
  const minIOPSPerGB = 3;
  const minIOPS = Math.max(minIOPSForInstance, minIOPSPerGB * diskSizeGB);

  const maxStandardIOPSForInstance = instance.maxStandardIOPS!;
  const maxIOPSForInstance = instance.maxIOPS || maxStandardIOPSForInstance;

  return Math.min(minIOPS, maxIOPSForInstance, maxStandardIOPSForInstance);
}

export function getMinIOPSForAzureSsdV2(): number {
  return 3500;
}

export function getMaxIOPSForAzureSsdV2(instance: Instance, diskSizeGB: number) {
  return Math.min(instance.maxIOPS!, Math.min(80000, 3000 + (diskSizeGB - 6) * 500));
}

export function getStandardIOPSForAzureSsdV2(instance: Instance, diskSizeGB: number) {
  return Math.min(
    getMaxIOPSForAzureSsdV2(instance, diskSizeGB),
    getMinIOPSForAzureSsdV2() + Math.max(0, diskSizeGB - 16) * 5
  );
}

export function getIOPSForAzureSsdV2(instance: Instance, diskSizeGB: number, IOPSToPreserve?: number) {
  const standardIOPS = getStandardIOPSForAzureSsdV2(instance, diskSizeGB);
  return Math.min(
    Math.max(getMinIOPSForAzureSsdV2(), !!IOPSToPreserve ? Math.max(standardIOPS, IOPSToPreserve) : standardIOPS),
    getMaxIOPSForAzureSsdV2(instance, diskSizeGB)
  );
}

export function getMinThroughputForAzureSsdV2() {
  return 125;
}

export function getMaxThroughputForAzureSsdV2() {
  return 1200;
}

export function getThroughputForAzureSsdV2(instance: Instance, diskSizeGB: number, diskIOPS: number) {
  const minThroughput = getMinThroughputForAzureSsdV2();
  const instanceMaxThroughput = instance.maxThroughput || Infinity;
  if (instance.maxThroughput! < minThroughput) {
    return instance.maxThroughput;
  }

  const standardIOPS = getStandardIOPSForAzureSsdV2(instance, diskSizeGB);
  return Math.floor(
    Math.min(
      // max throughput
      getMaxThroughputForAzureSsdV2(),
      // instance max throughput
      instanceMaxThroughput,
      // base throughput
      minThroughput +
        // add 0.2 for every GB over 256 GB
        Math.floor(Math.max(0, diskSizeGB - 256) * 0.2) +
        // add 1 for every 25 IOPS over standard IOPS
        Math.floor(Math.max(diskIOPS - standardIOPS, 0) / 25)
    )
  );
}

export function getProvisionedIOPS(instanceSize, diskSizeGB) {
  const minStandardIOPS = getStandardIOPS(instanceSize, diskSizeGB);
  const minProvisionedIOPS = getMinProvisionedIOPS(instanceSize, diskSizeGB);

  const minIOPS = Math.max(minStandardIOPS, minProvisionedIOPS);
  const maxIOPS = getMaxProvisionedIOPS(instanceSize, diskSizeGB);

  return Math.floor((maxIOPS - minIOPS) / 2 + minIOPS);
}

export function getMaxProvisionedIOPS(instanceSize, diskSizeGB) {
  const maxIOPSForInstance = instanceSize.maxIOPS;
  const maxIOPSPerGB = instanceSize.maxIOPSPerGB;

  return Math.min(maxIOPSForInstance, maxIOPSPerGB * diskSizeGB);
}

export function getSteppedDiskSizeGB(instanceSize, diskSizeGB, useInstanceSizeMaxStorage = false) {
  // Map the specified diskSizeGB to the storage option closest to the user's selected value
  const options = useInstanceSizeMaxStorage
    ? instanceSize.storageOptionsGB
    : instanceSize.storageOptionsGB.filter((size) => size <= instanceSize.maxAllowedStorageGB);
  const differences = options.map((size) => Math.abs(size - diskSizeGB));
  const minDifference = differences.reduce((a, b) => Math.min(a, b), Number.MAX_VALUE);
  return options[differences.indexOf(minDifference)];
}

export function calculateThroughput(providers, providerOptions, diskSizeGB, instance, useInstanceSizeMaxStorage) {
  if (providers.length === 1 && providers[0] === 'AZURE') {
    const mappedStorage = getSteppedDiskSizeGB(instance, diskSizeGB, useInstanceSizeMaxStorage);
    const options = providerOptions.AZURE.diskSizes[mappedStorage];
    const maxThroughput = instance.maxThroughput;
    if (instance.isNVMe) {
      return maxThroughput;
    }
    if (options) {
      return (maxThroughput && Math.min(options.throughputMBPerSecond, maxThroughput)) || 0;
    }
  }
  return 0;
}

export function ensureDiskSizeInBound(minStorageGB, maxStorageGB, diskSizeGB) {
  let ensuredDiskSizeGB = diskSizeGB;
  if (diskSizeGB <= minStorageGB) {
    ensuredDiskSizeGB = minStorageGB;
  } else if (diskSizeGB >= maxStorageGB) {
    ensuredDiskSizeGB = maxStorageGB;
  }
  return ensuredDiskSizeGB;
}

export function calculateMaxAllowedOplogSizeMB(
  diskSizeGB,
  clusterMaxDiskUsageMB,
  currentOplogUsageSizeMB,
  currentlySetOplogSizeMB: number | undefined = undefined,
  isNVMe = false
) {
  const maxDiskSpaceWithOplog = diskSizeGB * 1024 * (1 - MIN_FREE_PERCENTAGE_DISKSPACE_WITH_OPLOG);

  // this caps max allowance at the maxDiskSpaceWithOplog since clusterMaxDiskUsageMB - currentOplogUsageSizeMB can be negative
  const maxAllowedOplogSizeMB = Math.min(
    maxDiskSpaceWithOplog,
    maxDiskSpaceWithOplog - (clusterMaxDiskUsageMB - currentOplogUsageSizeMB)
  );

  // The maxAllowedOplogSize should not be any lower than the currently set oplog size
  return Math.max(
    Math.floor(maxAllowedOplogSizeMB),
    currentlySetOplogSizeMB || Math.floor(getDefaultOplogSizeMB(diskSizeGB, isNVMe))
  );
}

// Default oplog size is 5% (default - MongoDB) or 10% (NVMe - Atlas imposed) of free disk space with a lower bound of
// 990MB and upper bound of 50GB See https://docs.mongodb.com/manual/core/replica-set-oplog/ for details on the MongoDB
// default
export function getDefaultOplogSizeMB(diskSizeGB, isNVMe) {
  const defaultSize = Math.floor(diskSizeGB * 1024 * (isNVMe ? NVME_OPLOG_SIZE_PERCENT : DEFAULT_OPLOG_SIZE_PERCENT));
  return Math.min(Math.max(MIN_OPLOG_SIZE_MB_360_PLUS, defaultSize), MAX_DEFAULT_OPLOG_SIZE_MB);
}

// Default oplog minimum retention hours is 24 hours when auto-scaling is enabled or 0 hours otherwise.
export function getDefaultOplogMinRetentionHours(
  diskAutoScalingIsEnabled: boolean,
  isMongoDBVersion42OrBelow: boolean
): number {
  return diskAutoScalingIsEnabled && !isMongoDBVersion42OrBelow ? 24.0 : 0.0;
}

export function getDefaultOplogMinRetentionHoursPlaceholder(
  diskAutoScalingIsEnabled: boolean,
  isMongoDBVersion42OrBelow: boolean
): string {
  return diskAutoScalingIsEnabled && !isMongoDBVersion42OrBelow ? '24' : 'N/A';
}

export function isOplogSizeMBValid(
  processArgs,
  originalProcessArgs,
  diskSizeGB,
  clusterMaxDiskUsageMB,
  currentOplogUsageSizeMB,
  mongoDBVersion,
  isNVMe
) {
  const maxAllowedOplogSizeMB = calculateMaxAllowedOplogSizeMB(
    diskSizeGB,
    clusterMaxDiskUsageMB,
    currentOplogUsageSizeMB,
    originalProcessArgs.oplogSizeMB,
    isNVMe
  );
  const errorMsg = getOplogSizeMBErrorMsg(
    processArgs,
    originalProcessArgs,
    diskSizeGB,
    mongoDBVersion,
    maxAllowedOplogSizeMB,
    isNVMe
  );
  return errorMsg === '';
}

export function getOplogSizeMBErrorMsg(
  processArgs,
  originalProcessArgs,
  diskSizeGB?,
  mongoDBVersion?,
  maxAllowedOplogSizeMB?,
  isNVMe?
) {
  const oplogSizeMB = processArgs.oplogSizeMB;

  if (oplogSizeMB == null && originalProcessArgs.oplogSizeMB == null) {
    return '';
  }

  if (oplogSizeMB == null && originalProcessArgs.oplogSizeMB != null) {
    return 'Your oplog size cannot be empty';
  }

  if (oplogSizeMB <= 0) {
    return 'Your oplog size cannot be less than or equal to 0';
  }

  const reg = /^\d+$/;
  if (!reg.test(oplogSizeMB)) {
    return 'Your oplog size is not an integer';
  }

  if (parseFloat(mongoDBVersion) >= 3.6 && oplogSizeMB < MIN_OPLOG_SIZE_MB_360_PLUS) {
    return `Your oplog size cannot be changed to < ${MIN_OPLOG_SIZE_MB_360_PLUS} MB on MongoDB 3.6+`;
  }

  const minOplogSizeMB = Math.min(diskSizeGB * 1024 * NVME_OPLOG_SIZE_PERCENT, MAX_DEFAULT_OPLOG_SIZE_MB);
  if (isNVMe && oplogSizeMB < minOplogSizeMB) {
    return `Your oplog size must be at least ${minOplogSizeMB} MB for instances using local NVMe SSDs.`;
  }

  if (oplogSizeMB > maxAllowedOplogSizeMB) {
    return `
        Your oplog size cannot exceed ${maxAllowedOplogSizeMB.toLocaleString()} MB available. Atlas requires at least
        25% of free disk space for usage excluding the oplog. Consider upgrading your disk size if you need a larger
        oplog.`;
  }

  return '';
}

export function isOplogMinRetentionHoursValid(
  oplogMinRetentionHours: number | null,
  originalOplogMinRetentionHours: number | null,
  mongoDBMajorVersion: string,
  diskAutoScalingEnabled: boolean,
  clusterIsNVMe: boolean
) {
  if (oplogMinRetentionHours == null && originalOplogMinRetentionHours != null) {
    return false;
  }

  if (oplogMinRetentionHours == null || oplogMinRetentionHours === 0) {
    return true;
  }

  if (clusterIsNVMe && oplogMinRetentionHours < NVME_MIN_OPLOG_WINDOW) {
    return false;
  }

  if (!diskAutoScalingEnabled || parseFloat(mongoDBMajorVersion) < 4.4) {
    return false;
  }

  return oplogMinRetentionHours >= 0;
}

export function getOplogMinRetentionHoursErrorMsg(processArgs, originalProcessArgs, isNVMe) {
  if (processArgs.oplogMinRetentionHours == null && originalProcessArgs.oplogMinRetentionHours != null) {
    return 'Your oplog window cannot be empty.';
  }

  if (processArgs.oplogMinRetentionHours == null || processArgs.oplogMinRetentionHours === 0) {
    return '';
  }

  if (processArgs.oplogMinRetentionHours < 0) {
    return 'Your oplog window cannot be less than 0';
  }

  if (isNVMe && processArgs.oplogMinRetentionHours < NVME_MIN_OPLOG_WINDOW) {
    return `Your oplog window must be at least ${NVME_MIN_OPLOG_WINDOW} hours for instances using local NVMe SSDs.`;
  }

  return '';
}

export function updateAutoIndexing(clusterDescription, newAutoIndexing: AutoIndex) {
  const { replicationSpecList } = clusterDescription;
  const newReplicationSpecList = replicationSpecListUtils.updateAutoIndexing(newAutoIndexing, replicationSpecList);
  return {
    ...clusterDescription,
    replicationSpecList: newReplicationSpecList,
  };
}

export function isNVMe(providerOptions, provider, instanceSize) {
  if (provider === 'SERVERLESS' || provider === 'USS' || provider === 'FREE') {
    return false;
  }
  return !!providerOptions[provider].instanceSizes[instanceSize].isNVMe;
}

export function getTotalAnalyticsNodes(replicationSpecList: ReplicationSpecList): number {
  return replicationSpecList.reduce((accum, spec) => accum + replicationSpecListUtils.getTotalAnalyticsNodes(spec), 0);
}

export function allowContinuousBackupForVersion(mongoDBVersion: string): boolean {
  return parseFloat(mongoDBVersion) < 4.2;
}

export function usesIndexKeyLimit(mongoDBVersion: string): boolean {
  return parseFloat(mongoDBVersion) <= 4.0;
}

export function getDisabledComputeAutoScaling(compute = {}) {
  return {
    ...compute,
    enabled: false,
    scaleDownEnabled: false,
    minInstanceSize: null,
    maxInstanceSize: null,
  };
}

export function getRamFromRamPerInstanceFamily(instance: Instance | undefined, regionName: RegionName): number {
  if (!instance) {
    return 0.0;
  }
  const providers = new Set(instance.availableRegions.map((region) => region.providerName));
  // For cross-cloud, get the smallest ram size
  if (providers.size > 1) {
    return Object.values(instance.ramPerInstanceFamily).sort().shift() || 0.0;
  }

  const availableFamiliesInRegion =
    instance.availableRegions.find((region) => region.regionName === regionName)?.availableFamilies || [];

  const instanceFamilyToUse = [...availableFamiliesInRegion]
    .reverse() // prefer the latest InstanceFamily for the ram selection
    .find((instanceFamily) => instance.ramPerInstanceFamily[instanceFamily] !== undefined);

  return instanceFamilyToUse ? instance.ramPerInstanceFamily[instanceFamilyToUse] : 0.0;
}

export function canLoadSampleDataset({
  clusterDetail,
  loadSampleDatasetStatus,
  loadSampleDatasetStatusCollection,
}: CanLoadSampleDatasetArgs) {
  const clusterDescription = clusterDetail.getClusterDescription();
  const liveImport = clusterDetail.getLiveImport();
  const actionsDisabled = shouldDisableActions(clusterDescription);
  let loadSampleDataStatus = loadSampleDatasetStatus;
  if (!loadSampleDataStatus) {
    loadSampleDataStatus = loadSampleDatasetStatusCollection
      ? findLoadSampleDatasetStatusForCluster(loadSampleDatasetStatusCollection, clusterDescription)?.attributes
      : null;
  }

  return (
    // Cluster is ready to receive data:
    !actionsDisabled &&
    !liveImport?.isRunning() &&
    // Sample data addition hasn't been initiated already:
    loadSampleDataStatus?.state !== 'WORKING'
  );
}

export function instanceSizeToComputeUnit(instanceSize: InstanceSize) {
  if (isServerlessCluster(instanceSize)) {
    return 'Serverless Instance';
  }
  if (isFreeOrSharedTierCluster(instanceSize)) {
    return 'Shared Cluster';
  }
  return 'Dedicated Cluster';
}

export function isTenantUpgrade(isEdit: boolean, originalCluster: ClusterDescription | null) {
  if (!isEdit || originalCluster == null) {
    return false;
  }
  const originalInstanceSize = replicationSpecListUtils.getFirstInstanceSize(originalCluster.replicationSpecList);

  return isEdit && isFreeOrSharedTierCluster(originalInstanceSize);
}

export function getCurrentClusterVersionToShow(
  currentVersion: string,
  isContinuous: boolean,
  continuousDeliveryFCV?: string,
  fixedMongoDBFCV?: string,
  hasCustomerPinnedFcvEnabled?: boolean
): string {
  const cdFCVisCurrentVersion = continuousDeliveryFCV && currentVersion.startsWith(continuousDeliveryFCV);
  const mongodbFCVisCurrentVersion = fixedMongoDBFCV && currentVersion.startsWith(fixedMongoDBFCV);

  let currentVersionToShow = currentVersion;

  if (isContinuous) {
    if (cdFCVisCurrentVersion) {
      currentVersionToShow = currentVersion;
    } else if (!!continuousDeliveryFCV) {
      currentVersionToShow = continuousDeliveryFCV;
    }
  } else {
    if (mongodbFCVisCurrentVersion || hasCustomerPinnedFcvEnabled) {
      currentVersionToShow = currentVersion;
    } else if (!!fixedMongoDBFCV) {
      currentVersionToShow = fixedMongoDBFCV;
    }
  }

  return currentVersionToShow;
}

export function getRegionalOutageSimulationRegions(
  replicationSpec: ReplicationSpec,
  outageSimulation: ClusterOutageSimulation
): Array<{ cloudProvider: string; regionName: string }> {
  return outageSimulation.outageFilters.filter((filter) =>
    replicationSpec.regionConfigs.some(
      (rc) => filter.cloudProvider === rc.cloudProvider && filter.regionName === rc.regionName
    )
  );
}

export function getRegionalOutageSimulationMajorityLossZones(
  replicationSpecList: ReplicationSpecList,
  outageSimulation: ClusterOutageSimulation
): Array<string> {
  return replicationSpecList
    .map((rs) => {
      const totalElectableNodes = replicationSpecListUtils.getTotalElectableNodes(rs);
      const downElectableNodes = rs.regionConfigs
        .filter((rc) =>
          outageSimulation.outageFilters.some(
            (filter) => filter.cloudProvider === rc.cloudProvider && filter.regionName === rc.regionName
          )
        )
        .reduce((accum, curr) => accum + curr.electableSpecs.nodeCount, 0);

      return { id: rs.id, isMajorityMoss: downElectableNodes * 2 >= totalElectableNodes };
    })
    .filter((zone) => zone.isMajorityMoss)
    .map((zone) => zone.id);
}

export function isRegionalOutageSimulationMajorityLoss(
  clusterDescription: ClusterDescription,
  outageSimulation: ClusterOutageSimulation
): boolean {
  if (clusterDescription.forceReplicaSetReconfig) {
    return true;
  }

  return (
    getRegionalOutageSimulationMajorityLossZones(clusterDescription.replicationSpecList, outageSimulation).length > 0
  );
}

export function getSearchInstanceSizeDisplay(
  instanceSizeName: string,
  provider: string,
  providerOptions: ProviderOptionsModel
) {
  const sizes = providerOptions.get(provider).get('searchInstanceSizes') as Array<SearchInstanceSize>;
  const sizeDetails = sizes.find((size) => size.name === instanceSizeName);
  if (sizeDetails) {
    return `${sizeDetails.tierName} (${getSearchInstanceClassDisplay(sizeDetails.class)})`;
  }

  return getInstanceSizeDisplay(instanceSizeName);
}

export function getNextAvailableDefaultClusterName(
  allClusterNames: Array<string>,
  isServerless: boolean = false
): string {
  const clusterPrefix = isServerless ? 'ServerlessInstance' : 'Cluster';
  if (!allClusterNames.length) {
    return `${clusterPrefix}0`;
  }

  let clusterName;
  let clusterSuffix = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    clusterName = `${clusterPrefix}${clusterSuffix}`;
    if (isClusterNameUnused(clusterName, allClusterNames)) {
      return clusterName;
    }
    clusterSuffix++;
  }
}
