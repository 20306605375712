import { GlobalRole, GroupPermission, GroupRole, OrgRole, Permission, Role } from '@packages/types/roles';

const organizationPermissions: ReadonlyArray<Permission<OrgRole>> = [
  {
    value: OrgRole.ORG_OWNER,
    label: 'Organization Owner',
    description:
      'Provides root access to the organization, including: access to administer organization settings,' +
      ' users, and teams; access to delete the organization, and all permissions granted to the roles below.',
  },
  {
    value: OrgRole.ORG_GROUP_CREATOR,
    label: 'Organization Project Creator',
    description: 'Provides project creation access as well as permissions granted to the Organization Member role.',
  },
  {
    value: OrgRole.ORG_BILLING_ADMIN,
    label: 'Organization Billing Admin',
    description:
      'Provides access to administer billing information for the organization as well as permissions' +
      ' granted to the Organization Member role.',
  },
  {
    value: OrgRole.ORG_BILLING_READ_ONLY,
    label: 'Organization Billing Viewer',
    description:
      'Provides access to view billing information for the organization as well as permissions' +
      ' granted to the Organization Member role.',
  },
  {
    value: OrgRole.ORG_READ_ONLY,
    label: 'Organization Read Only',
    description:
      'Provides read-only access to everything in the organization, including all projects in the organization.',
  },
  {
    value: OrgRole.ORG_MEMBER,
    label: 'Organization Member',
    description:
      'Provides read-only access to the organization (settings, users, and billing) and the projects' +
      ' to which they belong.',
  },
];

const groupPermissions: ReadonlyArray<GroupPermission> = [
  {
    value: GroupRole.GROUP_OWNER,
    label: 'Project Owner',
    description: 'Has full administration access',
    isAtlas: true,
  },
  {
    value: GroupRole.GROUP_AUTOMATION_ADMIN,
    label: 'Project Automation Admin',
    description: 'Can administer automation',
  },
  {
    value: GroupRole.GROUP_BACKUP_ADMIN,
    label: 'Project Backup Admin',
    description: 'Can administer backups, and pull restores',
  },
  {
    value: GroupRole.GROUP_MONITORING_ADMIN,
    label: 'Project Monitoring Admin',
    description: 'Can administer hosts and alerts',
  },
  {
    value: GroupRole.GROUP_USER_ADMIN,
    label: 'Project User Admin',
    description: 'Can administer users',
  },
  {
    value: GroupRole.GROUP_CLUSTER_MANAGER,
    label: 'Project Cluster Manager',
    description: 'Can update clusters',
    isAtlas: true,
    ndsOnly: true,
  },
  {
    value: GroupRole.GROUP_DATA_ACCESS_ADMIN,
    label: 'Project Data Access Admin',
    description: "Can access and modify a cluster's data and indexes, and kill operations",
    isAtlas: true,
  },
  {
    value: GroupRole.GROUP_DATA_ACCESS_READ_WRITE,
    label: 'Project Data Access Read/Write',
    description: "Can access a cluster's data and indexes, and modify data",
    isAtlas: true,
  },
  {
    value: GroupRole.GROUP_DATA_ACCESS_READ_ONLY,
    label: 'Project Data Access Read Only',
    description: "Can access a cluster's data and indexes",
    isAtlas: true,
  },
  {
    value: GroupRole.GROUP_SEARCH_INDEX_EDITOR,
    label: 'Project Search Index Editor',
    description: "Can view and manage a cluster's search indexes",
    isAtlas: true,
    ndsOnly: true,
  },
  {
    value: GroupRole.GROUP_READ_ONLY,
    label: 'Project Read Only',
    description: 'May only modify personal preferences',
    isAtlas: true,
  },
  {
    value: GroupRole.GROUP_STREAM_PROCESSING_OWNER,
    label: 'Project Stream Processing Owner',
    description:
      'Has full administration access to stream processing instances including connection registry and stream processors. Can create DB Roles and Custom Roles.',
    isAtlas: true,
    ndsOnly: true,
  },
];

const globalPermissions: ReadonlyArray<Permission<GlobalRole>> = [
  {
    value: GlobalRole.GLOBAL_AUTOMATION_ADMIN,
    label: 'Global Automation Admin',
    description: 'Can administer automation',
  },
  {
    value: GlobalRole.GLOBAL_BACKUP_ADMIN,
    label: 'Global Backup Admin',
    description: 'Can administer backups, and pull restores',
  },
  {
    value: GlobalRole.GLOBAL_METERING_USER,
    label: 'Global Metering User',
    description: 'Can report metered usage',
  },
  {
    value: GlobalRole.GLOBAL_METRICS_INTERNAL_USER,
    label: 'Global Metrics Internal User',
    description: 'Can access internal metrics API',
  },
  {
    value: GlobalRole.GLOBAL_SERVERLESS_LIVENESS_ADMIN,
    label: 'Global Serverless Liveness Admin',
    description: 'Can access serverless liveness configuration',
  },
  {
    value: GlobalRole.GLOBAL_MONITORING_ADMIN,
    label: 'Global Monitoring Admin',
    description: 'Can administer hosts and alerts',
  },
  {
    value: GlobalRole.GLOBAL_OWNER,
    label: 'Global Owner',
    description: 'Has full administration access',
  },
  {
    value: GlobalRole.GLOBAL_READ_ONLY,
    label: 'Global Read Only',
    description: 'May only modify personal preferences',
  },
  {
    value: GlobalRole.GLOBAL_ADMIN_READ_ONLY,
    label: 'Global Admin Read Only',
    description: 'May view everything with a focus on the Admin UI panel',
  },
  {
    value: GlobalRole.GLOBAL_ORG_READ_ONLY,
    label: 'Global Organization Read Only',
    description: 'May view all organizations',
  },
  {
    value: GlobalRole.GLOBAL_USER_ADMIN,
    label: 'Global User Admin',
    description: 'Can administer users',
  },
  {
    value: GlobalRole.GLOBAL_USER_READ_ONLY,
    label: 'Global User Read Only',
    description: 'Can view users information',
  },
  {
    value: GlobalRole.GLOBAL_BILLING_ADMIN,
    label: 'Global Billing Admin',
    description: 'Provides access to administer billing information as well as permissions',
  },
  {
    value: GlobalRole.GLOBAL_BILLING_READ_ONLY,
    label: 'Global Billing Read Only',
    description: 'Can query some of the GET methods that GLOBAL_BILLING_ADMIN has access too',
  },
  {
    value: GlobalRole.GLOBAL_BILLING_FEATURE_TEAM_USER,
    label: 'Global Feature Team Billing User',
    description: 'Can access read-only billing endpoints for feature teams',
  },
  {
    value: GlobalRole.GLOBAL_METERING_FEATURE_TEAM_USER,
    label: 'Global Feature Team Metering User',
    description: 'Can access read-only metering endpoints for feature teams',
  },
  {
    value: GlobalRole.GLOBAL_FEATURE_FLAG_ADMIN,
    label: 'Global Feature Flag Admin',
    description: 'Can manage feature flags for any organization or project',
  },
  {
    value: GlobalRole.GLOBAL_APP_SETTING_ADMIN,
    label: 'Global App Setting Admin',
    description: 'Can edit global app settings',
  },
  {
    value: GlobalRole.GLOBAL_CRON_JOBS_ADMIN,
    label: 'Global Cron Jobs Admin',
    description: 'Can disable/enable and run cron jobs via admin ui',
  },
  {
    value: GlobalRole.GLOBAL_CHARTS_ADMIN,
    label: 'Global Charts Admin',
    description: 'Can administer Charts publishing functions',
  },
  {
    value: GlobalRole.GLOBAL_QUERY_ENGINE_INTERNAL_ADMIN,
    label: 'Global Data Lake Internal Admin',
    description: 'Can administer Data Lake internal API operations.',
  },
  {
    value: GlobalRole.GLOBAL_ACCOUNT_SUSPENSION_ADMIN,
    label: 'Global Account Suspension Admin',
    description: 'Can suspend organizations and delete all IP access lists and clusters of an organization',
  },
  {
    value: GlobalRole.GLOBAL_BAAS_ADMIN,
    label: 'Global App Services Admin',
    description: 'Can administer App Services operations',
  },
  {
    value: GlobalRole.GLOBAL_INFRASTRUCTURE_INTERNAL_ADMIN,
    label: 'Global Infrastructure Internal Admin',
    description: 'Can administer live import operations',
  },
  {
    value: GlobalRole.GLOBAL_SALESFORCE_ADMIN,
    label: 'Global Salesforce Admin',
    description: 'Can manage Salesforce and payment information',
  },
  {
    value: GlobalRole.GLOBAL_SALESFORCE_READ_ONLY,
    label: 'Global Salesforce Read Only',
    description: 'Can access Salesforce information',
  },
  {
    value: GlobalRole.GLOBAL_ATLAS_TSE,
    label: 'Global Atlas TSE',
    description: 'Can generate requestID tokens for SSH access to an Atlas server',
  },
  {
    value: GlobalRole.GLOBAL_ATLAS_OPERATOR,
    label: 'Global Atlas Operator',
    description: 'Can use the admin UI to take actions on projects or clusters',
  },
  {
    value: GlobalRole.GLOBAL_ATLAS_ADMIN,
    label: 'Global Atlas Admin',
    description: 'Can administer global configuration for Atlas',
  },
  {
    value: GlobalRole.GLOBAL_BAAS_INTERNAL_ADMIN,
    label: 'Global App Services Internal Admin',
    description: 'Can administer specific internal App Services operations',
  },
  {
    value: GlobalRole.GLOBAL_CCS_INTERNAL_ADMIN,
    label: 'Global Cluster Connection Service Internal Admin',
    description: 'Can administer specific internal Cluster Connection Service operations',
  },
  {
    value: GlobalRole.GLOBAL_SECURITY_ADMIN,
    label: 'Global Security Admin',
    description: 'Can access secure endpoints for logging users out',
  },
  {
    value: GlobalRole.GLOBAL_PROACTIVE_SUPPORT_ADMIN,
    label: 'Global Proactive Support Admin',
    description: 'Can send support emails and manage messages',
  },
  {
    value: GlobalRole.GLOBAL_LEGAL_ADMIN,
    label: 'Global Legal Admin',
    description: 'Can clear users and projects from OFAC checks',
  },
  {
    value: GlobalRole.GLOBAL_EXPERIMENT_ASSIGNMENT_ADMIN,
    label: 'Global Experiment Assignment Admin',
    description: 'Can request for entities to be allocated into AB Tests',
  },
  {
    value: GlobalRole.GLOBAL_EXPERIMENT_OVERRIDE_ASSIGNMENT_ADMIN,
    label: 'Global Experiment Override Assignment Admin',
    description: 'Can override AB Test assignments',
  },
  {
    value: GlobalRole.GLOBAL_ATLAS_SOFTWARE_VERSION_ROLLOUT_ADMIN,
    label: 'Global Atlas Software Version Rollout Admin',
    description: 'Can control the rollout of software versions to the Atlas fleet',
  },
  {
    value: GlobalRole.GLOBAL_PARTNER_ADMIN,
    label: 'Global Partner Admin',
    description: 'Can use the admin UI to suspend IP-based rate limiting',
  },
  {
    value: GlobalRole.GLOBAL_CRASH_LOG_ANALYST,
    label: 'Global Crash Log Analyst',
    description: 'Can use the API to help analyze crash logs for search and atlas deployments',
  },
  {
    value: GlobalRole.GLOBAL_BAAS_FEATURE_ADMIN,
    label: 'Global BaaS Feature Admin',
    description: 'Can administer feature flags for BaaS',
  },
  {
    value: GlobalRole.GLOBAL_BAAS_SUPPORT,
    label: 'Global BaaS Support',
    description: 'Can support the BaaS platform',
  },
  {
    value: GlobalRole.GLOBAL_UNIFORM_FRONTEND_ENVOY,
    label: 'Uniform Frontend Envoy',
    description: 'A uniform frontend envoy instance',
  },
];

const permissionTypes = {
  group: groupPermissions,
  organization: organizationPermissions,
  global: globalPermissions,
};

const permissionValueToLabelMap = (groupPermissions as Array<Permission<Role>>)
  .concat(organizationPermissions)
  .concat(globalPermissions)
  .reduce(
    (acc, permission) => {
      acc[permission.value] = permission.label;
      return acc;
    },
    {} as Record<Role, string>
  );

const ndsGroupPlanPermissions: Array<GroupPermission> = groupPermissions.filter((permission) => permission.isAtlas);

export { permissionTypes, permissionValueToLabelMap, ndsGroupPlanPermissions, organizationPermissions };

export const getNonNDSOnlyPermissions = (permissions: Readonly<Array<Permission<Role>>>): Array<Permission<Role>> =>
  permissions.filter((p) => p.ndsOnly !== true);
