import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { ErrorEvent, EventHint, Primitive } from '@sentry/types';
import { useSelector } from 'react-redux';
import { type History } from 'react-router-dom-v5-compat-history';

import { Application, CloudTeams, ErrorMaybe, ErrorSeverity, ErrorSeverityLevel } from '@packages/types/observability';
import { StrictUnion } from '@packages/types/utilTypes';

import { Environment, getEnvironment } from '@packages/get-environment';

import { isCreateUserError } from './isCreateUserError';
import { sanitizeLocation } from './url-utils';

declare global {
  interface Window {
    errorTrackerSetupData: {
      appVersion?: string;
      gitVersion?: string;
    };
  }
}

export type ApprovedTags = StrictUnion<
  | {
      applicationName: Application;
    }
  | {
      applicationName: Application;
      oktaUserId: string;
      username: string;
      email: string;
      firstName: string;
      lastName: string;
    }
>;

export type ApprovedExtras = StrictUnion<unknown>;

export interface SendErrorSpecs {
  error: ErrorMaybe;
  extras?: {};
  tags?: Record<string, Primitive>;
  team: CloudTeams;
  severity?: ErrorSeverityLevel;
  sampleRate?: number | undefined;
}

export const sentryDsn = 'https://6135b17dfd8e46ec8da3e5cfdca571d0@o4504991346720768.ingest.sentry.io/4505240668733440';

const EXTRA_INFO_CTA = 'See Additional Data section for related information.';

const getWindowLocation = () => sanitizeLocation(window.location, { idPlaceholder: true });

export const init = (history: History) => {
  const { browserErrorTrackingEnabled } = window.REQUEST_PARAMS || {};

  try {
    if (!browserErrorTrackingEnabled) {
      console.warn('Browser error tracking is disabled');
      return;
    }

    // appVersion is branch (e.g. "v20230524")
    // gitVersion is commit hash (e.g. "8564322165678a709dc72145d52646911224b665")
    const { appVersion: branch } = window.errorTrackerSetupData;

    const environment = getEnvironment();

    let tracesSampleRate;
    let profilesSampleRate;
    if (environment === Environment.LOCAL) {
      // turn on here for local testing
      tracesSampleRate = 0;
      profilesSampleRate = 0;
    } else if (environment === Environment.E2E) {
      tracesSampleRate = 0;
      profilesSampleRate = 0;
    } else if (environment === Environment.PROD) {
      tracesSampleRate = 0.5;
      profilesSampleRate = 0;
    } else if (environment === Environment.QA) {
      tracesSampleRate = 1;
      profilesSampleRate = 0;
    } else {
      // DEV
      tracesSampleRate = 0.2;
      // for profiling to work, the "Document-Policy" header must be set to "js-profiling"
      // this is currently only set for DEV environment
      profilesSampleRate = 0.05; // calculates actual rate as profilesSampleRate * tracesSampleRate
    }

    Sentry.init({
      dsn: sentryDsn,
      release: branch,
      environment,
      beforeSend: sentryBeforeSend,
      normalizeDepth: 10,
      tracesSampleRate,
      profilesSampleRate,
      integrations: [
        Sentry.browserTracingIntegration({
          // disable automatic span creation
          instrumentNavigation: false,
          instrumentPageLoad: false,
        }),
        Sentry.browserProfilingIntegration(),
      ],
      // This error seems to happen only in firefox and is ignorable
      ignoreErrors: ['AbortError: The operation was aborted'],
    });

    const client = Sentry.getClient();

    // Custom load and navigation spans since we have both React and Backbone
    // In the future when we just have React Router, we can consider using their
    // built in support for that and remove this custom code.
    Sentry.startBrowserTracingPageLoadSpan(client!, {
      name: getWindowLocation(),
      attributes: {
        [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url',
      },
    });

    history.listen(() => {
      Sentry.startBrowserTracingNavigationSpan(client!, {
        op: 'navigation',
        name: getWindowLocation(),
        attributes: {
          [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url',
        },
      });
    });
  } catch (e) {
    console.error('Failed to initialize Sentry', e);
  }

  try {
    const { gitVersion: commitHash } = window.errorTrackerSetupData;
    if (commitHash) {
      Sentry.setTag('commitHash', commitHash);
    } else {
      throw new Error('No commit hash present. No gitVersion present in window.errorTrackerSetupData');
    }
  } catch (e) {
    console.error('Failed to configure Sentry scope', e);
  }
};

interface EventBody {
  sourceFile: string;
}

export const sentryBeforeSend = (event: ErrorEvent, hint: EventHint) => {
  // do not send errors from chrome extensions
  const eventBody = (event.extra?.body || {}) as EventBody;
  if ((eventBody.sourceFile || '').startsWith('chrome-extension://')) {
    return null;
  }

  // Check for sampling:
  if (event.exception && hint.originalException instanceof Error) {
    if (
      // Using checkIfErrorInSample in beforeSend instead of
      // tracesSampler so sample logic is consistent with sendError
      !utils.checkIfErrorInSample({ error: hint.originalException })
    ) {
      return null;
    }

    event.level = utils.getErrorSeverity({
      severity: event.level || ErrorSeverity.ERROR,
      error: hint.originalException,
      team: event.tags?.team as CloudTeams,
    });
  } else {
    // non-error objects such as rejected promises and thrown primitives. These have not passed through the sendError logic
    // and will circle back into this function after being formatted in the sendError method and triggered through captureException
    // and subsequently pass the if counterpart of this condition. Hence, return a null so as to not double trigger the event.
    if (event.exception?.values) {
      // if no team information is found, leave it unassigned
      const team: CloudTeams = (event.tags?.team as CloudTeams) || CloudTeams.Triage;
      sendError({
        error: event.exception.values[0],
        extras: event,
        team,
        severity: event.level || ErrorSeverity.ERROR,
      });
      return null;
    }
  }
  return event;
};

export const updateErrorTrackerState = ({ tags, extras }: { tags?: ApprovedTags; extras?: ApprovedExtras }) => {
  const scope = Sentry.getCurrentScope();
  if (tags) {
    scope.setTags(tags);
  }
  if (extras) {
    scope.setExtras(extras);
  }
};

export const setErrorTrackerState = ({
  auid,
  userId,
  email,
  orgId,
  groupId,
  applicationName,
}: {
  auid: string;
  userId: string;
  email: string;
  orgId?: string;
  groupId?: string;
  applicationName: Application;
}): void => {
  try {
    const scope = Sentry.getCurrentScope();
    // Set tags.  These will be indexed by Sentry, and can be used to filter errors.
    scope.setTags({
      auid,
      userId,
      email,
      applicationName,
      orgId,
      groupId,
    });
  } catch (e) {
    console.error(e);
  }
};

type Selector = (state: unknown) => string;

export const useErrorTrackerState = ({
  applicationName,
  viewer,
  settings,
}: {
  applicationName: Application;
  viewer: {
    getId: Selector;
    getUsername: Selector;
    getCurrentOrgId: Selector;
    getCurrentProjectId: Selector;
  };
  settings: {
    getAuid: Selector;
  };
}) => {
  const userId = useSelector(viewer.getId);
  const email = useSelector(viewer.getUsername); // Email
  const orgId = useSelector(viewer.getCurrentOrgId);
  const groupId = useSelector(viewer.getCurrentProjectId);
  const auid = useSelector(settings.getAuid);

  useEffect(() => {
    setErrorTrackerState({
      auid,
      userId,
      email,
      orgId,
      groupId,
      applicationName,
    });
  }, [auid, userId, email, orgId, groupId, applicationName]);
};

export const testHandle = {
  useErrorTrackerState,
};

// Hooks aren't allowed in class components.  Adding this component to the top of the render
// of a class component will allow for the enclosed hook to still do its job in a class component.
export const SetUpErrorTrackerState = ({
  applicationName,
  viewer,
  settings,
}: {
  applicationName: Application;
  viewer: {
    getId: (state: unknown) => string;
    getUsername: (state: unknown) => string;
    getCurrentOrgId: (state: unknown) => string;
    getCurrentProjectId: (state: unknown) => string;
  };
  settings: {
    getAuid: (state: unknown) => string;
  };
}) => {
  testHandle.useErrorTrackerState({
    applicationName,
    viewer,
    settings,
  });
  return <React.Fragment />;
};

/**
 * The experiment SDK errors are structured like this - "Experiment SDK: <error message with allocation/experiment context>".
 * This helper will extract the allocation or experiment name from the regexp match patterns
 * Examples:
 *  - "Experiment SDK: The operation timed out, allocationPoints: PROJECT_APPLICATION_START"
 *    -> match pattern = [ ', allocationPoints: APPLICATION_CLIENTS_20230823' ]
 *    -> returns "PROJECT_APPLICATION_START"
 *  - "Experiment SDK: TypeError: Failed to fetch, response from API: undefined, experimentName: APPLICATION_CLIENTS_20230823" -> returns "APPLICATION_CLIENTS_20230823"
 * @param match - the Regexp match array returned from the experiment SDK error message match
 * @return string - allocation name value or experiment name value
 */
const extractValuesFromExperimentSDKError = (match: RegExpMatchArray): string => {
  return match[0]
    .trim()
    .split(':')[1]
    .trim()
    .replace(/(\s*,?\s*)*$/, '');
};

/**
 * Special treatment of Experiment SDK errors The experiment SDK errors are structured like this:
 *  - "Experiment SDK: <error message sometimes with allocation/experiment name context>".
 *
 * This helper will apply logic to remove the dynamic content in the error message and return
 * the extracted allocation point names and experiment names, so they can be sent to Sentry as tags.
 *
 * Steps:
 *  - should match "*Experiment SDK*" errors only
 *  - normalize the error messages by replacing all dynamic values (allocation and experiment name) with a blank string
 *  - preserve the allocation and experiment name context by returning them as tags to be used in the Sentry call
 *
 * Example:
 *  - "Experiment SDK: The operation timed out., allocationPoints: PROJECT_APPLICATION_START, experimentName: APPLICATION_CLIENTS_20230823"
 *    - Returns {
 *        experimentSDKMessage: "Experiment SDK: The operation timed out.",
 *        experimentSDKTags: {
 *          allocationPointName: "PROJECT_APPLICATION_START",
 *          experimentName: "APPLICATION_CLIENTS_20230823",
 *        }
 *      }
 *
 * @see https://jira.mongodb.org/browse/CLOUDP-224254 for context
 *
 * @param errorMessage - the experiment SDK error message
 * @return {
 *   experimentSDKMessage: formatted message from the SDK
 *   experimentSDKTags: object containing experiment name or allocation name tags
 * }
 */
const handleExperimentSDKError = (
  errorMessage: string
): { experimentSDKMessage: string; experimentSDKTags: Record<string, Primitive> } => {
  const experimentSDKTags: Record<string, Primitive> = {};
  let experimentSDKMessage: string = errorMessage;

  try {
    // matches the following parts of an SDK error message:
    // ',allocationPoints: "PROJECT_APPLICATION_START", allocationPointNames: "PROJECT_APPLICATION_START"'
    // note that both "allocationPoints" and "allocationPointNames" can appear in the error message
    const allocationPointsRegexp = /,?\s?(allocationPoints|allocationPointNames):[ _A-Z0-9,]*,?\s?/g;
    const allocationPointsMatch = experimentSDKMessage.match(allocationPointsRegexp);
    if (allocationPointsMatch) {
      const allocationPointsValue = extractValuesFromExperimentSDKError(allocationPointsMatch);
      experimentSDKTags['allocationPointNames'] = allocationPointsValue;
      experimentSDKMessage = experimentSDKMessage.replace(allocationPointsRegexp, '');
    }

    // matches the following parts of an SDK error message:
    // ',experimentName: "PROJECT_APPLICATION_START", experimentNames: "PROJECT_APPLICATION_START"'
    // note that both "experimentName" and "experimentNames" can appear in the error message
    const experimentNameRegexp = /,?\s?experimentNames?:[ _A-Z0-9,]*,?\s?/g;
    const experimentNameMatch = experimentSDKMessage.match(experimentNameRegexp);
    if (experimentNameMatch) {
      const experimentNameValue = extractValuesFromExperimentSDKError(experimentNameMatch);
      experimentSDKTags['experimentName'] = experimentNameValue;
      experimentSDKMessage = experimentSDKMessage.replace(experimentNameRegexp, '');
    }

    return { experimentSDKMessage, experimentSDKTags };
  } catch (e) {
    console.error('failed to handle Experiment SDK error', e);
    // if there is an error return the original error message and an empty tags object
    return { experimentSDKMessage: errorMessage, experimentSDKTags: {} };
  }
};

/**
 * Remove common error tracking methods from stack frames for better stack tracing
 * @param error the actual Error
 * @returns a modified stack trace of the passed error with error tracker frames removed
 */
const removeErrorTrackerFramesFromErrorStack = (error: Error): string => {
  // Convert the error stack to an array of stack frames
  const stackFrames = error.stack?.split('\n') || [];
  // Define the methods to be removed
  const methodsToRemove = ['sendError', 'coerceError'];
  // Iterate through the stack frames
  for (let i = stackFrames.length - 1; i >= 0; i--) {
    // Check if the current stack frame contains any method to be removed
    if (methodsToRemove.some((method) => stackFrames[i].includes(method))) {
      // Remove the current stack frame
      stackFrames.splice(i, 1);
    }
  }
  // Join the modified stack frames back into a string
  const modifiedStack = stackFrames.join('\n');
  // Return modified stack
  return modifiedStack;
};

export const sendError = ({
  error: maybeError,
  extras = {},
  tags,
  team,
  severity = ErrorSeverity.ERROR,
  sampleRate,
}: SendErrorSpecs): string | null => {
  let result: string | null = null;

  try {
    const browserErrorTrackingEnabled = (window as any).REQUEST_PARAMS?.browserErrorTrackingEnabled;
    if (!browserErrorTrackingEnabled) {
      return result;
    }

    // Convert non-errors into errors:
    const { error, nonErrorThrownObject } = coerceError(maybeError);

    const notInSample = !utils.checkIfErrorInSample({ error, sampleRate });
    if (notInSample) {
      return result;
    }

    let experimentSdkTags: Record<string, Primitive>;
    let detectedLanguageTag: Record<string, string>;
    if (team === CloudTeams.AtlasGrowth) {
      detectedLanguageTag = {
        detectedLanguage: document.documentElement.lang,
      };
      // Additional logic for updating experiment SDK errors
      if (/.*Experiment\sSDK:.*$/.test(error.message)) {
        const experimentSDKHandlerResult = handleExperimentSDKError(error.message);
        error.message = experimentSDKHandlerResult.experimentSDKMessage;
        experimentSdkTags = experimentSDKHandlerResult.experimentSDKTags;
      }
    }
    // Assign the modified stack back to the error
    error.stack = removeErrorTrackerFramesFromErrorStack(error);

    Sentry.withScope((scope) => {
      const severityParams = {
        severity,
        error,
        errorCode: team === CloudTeams.AtlasGrowth ? nonErrorThrownObject?.errorCode : undefined,
        statusCode: team === CloudTeams.AtlasGrowth ? nonErrorThrownObject?.statusCode : undefined,
      };
      let adjustedSeverity = utils.getErrorSeverity(severityParams);
      scope.setLevel(adjustedSeverity);
      scope.setExtras({ ...extras, nonErrorThrownObject });
      result = Sentry.captureException(error, {
        tags: {
          ...tags,
          team,
          ...(experimentSdkTags || {}),
          ...(detectedLanguageTag || {}),
        },
      });
    });
  } catch (e) {
    console.error(e);
  }
  return result;
};

/**
 * Pattern match and strip away any Object Ids in the request URL
 * @param requestUrl the URL to be processed
 * @returns a stripped out version of the requestUrl replacing any ObjectIds with <scrubbed>
 */
const stripVarArgsFromUrl = (requestUrl: string): string => {
  const myregexp = /[0-9a-fA-F]{24}/g;
  const strippedUrl = requestUrl.replaceAll(myregexp, '<scrubbed>');
  return strippedUrl;
};

/**
 * Remove all query params from the given request URL
 * @param requestUrl the URL to be processed
 * @returns a stripped out version of the requestUrl sans any query params
 */
const stripQueryParamsFromUrl = (requestUrl: string): string => {
  try {
    const url = new URL(requestUrl);
    url.search = '';
    return stripVarArgsFromUrl(url.toString());
  } catch {
    // generally a TypeError indicating that the requestUrl is not a valid URL
    return stripVarArgsFromUrl(requestUrl);
  }
};

/**
 * Our error service only accepts Errors and strings as its error arg. In our fetchWrapper (and potentially elsewhere),
 * entities other than Errors are thrown.  This function will convert whatever is fed into sendError into an actual error.
 */
const coerceError = (errorMaybe: ErrorMaybe): { error: Error; nonErrorThrownObject: ErrorMaybe | null } => {
  // If coercion fails, we'll default to this error:
  let error: Error = new Error('Unknown error');
  let nonErrorThrownObject: ErrorMaybe | null = null;

  if (errorMaybe instanceof Error) {
    error = errorMaybe;
  } else {
    // If an object was passed in rather than an error, we'll collect this to send along as metadata
    nonErrorThrownObject = errorMaybe;

    if (typeof errorMaybe !== 'object') {
      if (typeof errorMaybe === 'string') {
        // convert thrown strings to Error objects
        error = new Error(errorMaybe);
      }
      // Let the "Unknown error" pass through
    } else if ('error' in errorMaybe) {
      // fetchWrapper can generate objects with an error param in some cases.
      // The following shouldn't be necessary, but TS won't believe the nested error is defined unless we do this.
      const { error: nestedError } = errorMaybe;
      if (nestedError) {
        error = nestedError;
      }
    } else if ('requestUrl' in errorMaybe && 'errorCode' in errorMaybe) {
      // fetchWrapper can generate objects matching this signature
      const { requestUrl, errorCode } = errorMaybe;
      error = new Error(`Due to ${requestUrl}, failed to reach ${errorCode}`);
      const strippedRequestUrl = stripQueryParamsFromUrl(requestUrl!);
      error.message = `Failed to reach ${strippedRequestUrl}`;
      error.name = errorCode!;
      if ('statusCode' in errorMaybe) {
        const { statusCode } = errorMaybe;
        error.name = `${error.name}: ${statusCode}`;
      }
    } else if (errorMaybe?.type === 'Error' || errorMaybe?.type === 'UnhandledRejection') {
      // thrown Exceptions have 'type = Error' but aren't Error objects
      // rejected Promises which don't wrap the rejected payload in an Error have 'type = UnhandledRejection'
      error.name = errorMaybe.type;
      error.message = errorMaybe?.value || error.message;
      error.cause = errorMaybe.cause || error.cause;
    }

    // Message to user that additional context is available:
    error.message = `${error.message}. ${EXTRA_INFO_CTA}`;
  }
  return { error, nonErrorThrownObject };
};

/**
 * A function to check for a list of known errors that we would like to downgrade
 * from a severity ERROR. Possible downgrade options are WARNING | INFO
 */
const getErrorSeverity = ({
  severity,
  errorCode,
  statusCode,
  error,
  team,
}: {
  severity: ErrorSeverityLevel;
  error: Error;
  errorCode?: string;
  statusCode?: number;
  team?: CloudTeams;
}): ErrorSeverityLevel => {
  if (severity !== ErrorSeverity.ERROR) return severity;

  // the default case
  let finalSeverity = ErrorSeverity.ERROR;

  switch (errorCode) {
    case 'IP_NOT_USER_EDITABLE':
    case 'NO_AUTHORIZATION':
    case 'NETWORK_ERROR':
    case 'UNAUTHORIZED':
    case 'SERVICE_MAINTENANCE':
      finalSeverity = ErrorSeverity.WARNING;
      break;
    case 'SERVER_ERROR':
      if (statusCode && [500, 503].includes(statusCode)) finalSeverity = ErrorSeverity.WARNING;
      break;
    default:
      finalSeverity = ErrorSeverity.ERROR;
  }

  const warningErrorMessages = [
    /can't access dead object/,
    /Experiment.*aborted/i,
    /Experiment.*timed out/i,
    // Loading Chunk Failed error has been downgraded to a warning, our current hypothesis is that is caused by
    // idiosyncratc behavior on the client's network requests
    // Hopefully, CLOUDP-168300 will help resolve these
    /Loading chunk.*failed/i,
    // This error message is intentional and informational to the user, but we do not want to be alerted everytime the user sees this so the severity is downgraded
    /Cannot upgrade shared cluster while Realm Sync is setup/,
    // handled error from CreateUser flow
    /(Username|Password) should not contain any whitespace/i,
    /Experiment.*Failed to fetch/,
    /Experiment.*NetworkError when attempting to fetch resource/,
    // this error gets thrown when user session expires and an AB Test allocation point is then hit
    /Experiment SDK: SyntaxError:.*unexpected end of.*JSON/i,
    // Thrown when user's authentication session expires
    /Failed to reach.+assign.+due to FORBIDDEN/,
    // Ecosystem errors
    /ecosystem: Failed to fetch/i,
    /CMS: Error fetching integration with key:/i,
  ];

  if (warningErrorMessages.some((msg) => error.message.match(msg))) {
    finalSeverity = ErrorSeverity.WARNING;
  }

  if (team === CloudTeams.AtlasGrowth && error.message.match(/Load failed/i)) {
    // Load failed error has been downgraded to a warning since it's mostly affecting Safari users
    // and the error itself isn't very helpful or reproducible so far.
    // More info: https://sentry.io/answers/load-failed-javascript/
    finalSeverity = ErrorSeverity.WARNING;
  }
  return finalSeverity;
};

/**
 * We'd like to sample certain abundant errors so as to not eat up too much quota.
 * This function returns true if the error occurrence should be in the sample sent to Sentry.
 */

// these error patterns can occur from the 1s API timeout
const sdkSamplingErrors = [
  /Experiment.*aborted/i,
  /Experiment.*timed out/i,
  /Experiment.*Experiment data was not fetched or entity is not in experiment/i,
  /Experiment.*API request timed out/i,
];

const checkIfErrorInSample = ({
  error,
  requestUrl = '',
  sampleRate,
}: {
  error: Error;
  requestUrl?: string;
  sampleRate?: number;
}): boolean => {
  let isInSample = true;

  if (sampleRate !== undefined) {
    // check sample and exit early if user provides sampleRate
    isInSample = Math.random() < sampleRate;
  } else if (requestUrl.includes('automation')) {
    // noisy errors from AutomationChangesClient/Api that we want to sample while
    // we investigate root cause
    // sampling at 1/100
    isInSample = Math.random() < 0.01;
  } else if (isCreateUserError(error.message)) {
    // filter these out
    isInSample = false;
  } else if (sdkSamplingErrors.some((msg) => error.message.match(msg))) {
    // sampling at 1/1000
    isInSample = Math.random() < 0.001;
  }

  return isInSample;
};

// For unit testing purposes only:
export const utils = {
  coerceError,
  getErrorSeverity,
  checkIfErrorInSample,
};
