import * as BaasAdminSDK from 'baas-admin-sdk';
import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';

import * as api from '@packages/common/services/api';
import { updateCsrfHeaders } from '@packages/common/services/api/fetchWrapper';
import analytics from '@packages/common/utils/segmentAnalytics';
import { isMongodbUrl, isMongodbUrlStr, originUrlHasSupportedProtocol } from '@packages/common/utils/urlUtils';

interface JustLoggedInRedirectParams {
  userId?: string;
  email?: string;
  baasCentralUrl: string;
  baasLegacyUrl?: string;
  centralUrl?: string;
  csrfTime?: string;
  csrfToken?: string;
  needsMfa?: boolean;
  username?: string;
  windowLocation: Pick<Location, 'assign'>;
  query: Search;
  segmentDefaultUserId?: string;
  personalizationWizardRedirect?: boolean;
  shouldTransitionToAccountMfa?: boolean;
}

export interface ParamsBasedRedirectProps {
  baasCentralUrl: string;
  baasLegacyUrl?: string;
  centralUrl?: string;
  csrfTime?: string;
  csrfToken?: string;
  username?: string;
  windowLocation: Pick<Location, 'assign'>;
  query: Search;
}

type FromURI = null | undefined | string;

const nextPageQueryKey = 'n';
const nextHashQueryKey = 'nextHash';
const registrationSuccessPagePath = '/account/register/success';

const allowedRedirectHostnames = ['mongodb-dev.okta.com', 'mongodb-qa.oktapreview.com', 'mongodb.okta.com'];

function isValidFromUri(fromUri: string, isLocal: boolean) {
  const urlObj = new URL(fromUri, window.location.origin);
  // Include redirects to mongodb okta environments
  return isMongodbUrl(urlObj, isLocal) || allowedRedirectHostnames.includes(urlObj.hostname);
}

export async function authenticateWithBaas(username: string, baasCentralUrl: string, centralUrl?: string) {
  const adminClient = new BaasAdminSDK.BaasAdminClient(baasCentralUrl, { requestOrigin: 'mongodb-mms-ui' });
  const { key } = await api.settings.addTempApiKey('Stitch API Key', centralUrl);
  await adminClient.authenticate(BaasAdminSDK.AuthProviderType.MongoDBCloud, {
    username,
    apiKey: key,
    cors: true,
    cookie: true,
  });
}

/**
 * When a logged out user tries to go to a logged in page they get redirect to
 * this registration app with the previous URL given as a query string, i.e.
 * "/account/login?n=%2Fv2%GROUPID#account/myGroups".
 * Since the v2 app also uses hashes for navigation the "#account/myGroups" is
 * stripped away from the registration app.
 * This augments that url by encoding the hash value so it can be put into the
 * query string.
 * @param {Location} location The window.location object.
 * @return {string} The full URL with nextPage in a query param.
 */
export function createUrlWithNextHash(location: Pick<Location, 'pathname' | 'search' | 'hash'>) {
  const { pathname, search, hash } = location;

  // Don't calculate the URL if:
  //      There is no n= in the path
  //      OR
  //      If there is already a nextHash query param key
  //      OR
  //      The hash value is an empty string, meaning we don't need to redirect
  //          as there is no value to persist between page refreshes.
  if (!search.includes(`${nextPageQueryKey}=`) || search.includes(`${nextHashQueryKey}=`) || hash === '') {
    return null;
  }

  const queryParam = queryString.parse(search);

  // If our hash has a leading `#/` we want to prune it to just be `#` for v2 compatibility.
  const sanitizedHash = hash.replace(/^#\//, '#');

  if (sanitizedHash) {
    queryParam[nextHashQueryKey] = sanitizedHash;
  }

  if (!originUrlHasSupportedProtocol(pathname)) {
    return null;
  }

  let forceHash = '';
  if (queryParam.forceHash) {
    forceHash = `#${queryParam.forceHash}`;
    queryParam.forceHash = null;
  }

  const searchStrWithNextHash = `?${queryString.stringify(queryParam, { skipNull: true })}${forceHash}`;

  return searchStrWithNextHash;
}

export function sanitize(location: Pick<Location, 'pathname' | 'search' | 'hash'>) {
  let { pathname, hash, search } = location;

  const sanitizedHash = hash.split('/');
  const planType = sanitizedHash[1];

  if (hash.includes(`${planType}/reset/2fa`)) {
    pathname += `reset/mfa/${planType}`;
    hash = '';
  } else if (sanitizedHash[sanitizedHash.length - 1].endsWith('password')) {
    pathname += 'reset/password';
    hash = '';
  } else {
    // append the hash as 'nextHash' query param so user is redirected to their lastSeen page correctly
    // We should preserve the queryParams if/when searchStrWithNextHash is null as nextHash may already be present
    search = createUrlWithNextHash(location) || search;
    hash = '';
  }

  return { pathname, search, hash };
}

export function sanitizeFromURI(fromURI: FromURI, centralUrl: string): FromURI {
  if (!fromURI) {
    return null;
  }
  const isLocal = centralUrl?.includes('localhost');
  if (!isValidFromUri(fromURI, isLocal)) {
    return null;
  }
  return fromURI;
}

export function createUrlFromSearchString(search: string): string {
  const queryParams = queryString.parse(search) as Search;

  if (!(nextPageQueryKey in queryParams)) {
    return `${window.location.origin}/`;
  }

  const pathname = queryParams[nextPageQueryKey] ?? '';
  const url = buildSearchUrl(pathname, queryParams);
  url.hash = queryParams[nextHashQueryKey] || '';

  const preserveQuery = pathname === registrationSuccessPagePath || pathname.includes('/account/vercel/');
  if (preserveQuery) {
    delete queryParams[preserveQuery as any];
    delete queryParams[nextPageQueryKey];
    delete queryParams[nextHashQueryKey];
    url.search = queryString.stringify(queryParams);
  }

  return url.href;
}

export const justLoggedInRedirect = async ({
  userId,
  email,
  baasCentralUrl = '',
  baasLegacyUrl,
  centralUrl,
  csrfTime,
  csrfToken,
  needsMfa,
  username = '',
  windowLocation = window.location,
  query,
  segmentDefaultUserId,
  personalizationWizardRedirect,
  shouldTransitionToAccountMfa,
}: JustLoggedInRedirectParams) => {
  const { groupId } = query;

  // prevent personalizationWizardRedirect param from being passed to MFA flow
  if (query.personalizationWizardRedirect) {
    delete query.personalizationWizardRedirect;
  }

  const queryParams = queryString.stringify(query, { sort: false });

  const identifier = segmentDefaultUserId || userId;
  if (identifier) {
    const helioProps = userId ? { persistedProperties: { cloud_user_id: userId } } : {};
    await analytics.updateState({
      ...helioProps,
    });
  }

  analytics.identify({
    username,
    email,
    event: 'Logged In',
  });

  /* this will ensure that users with MFA, logging in with invite flow gets redirected correctly to their
   invited org post MFA validation.
  */
  if (needsMfa) {
    windowLocation.assign(`/account/login/mfa?${queryParams}`);
    return;
  } else if (shouldTransitionToAccountMfa) {
    windowLocation.assign(`/account/transition/mfa?${queryParams}`);
    return;
  }

  /* The personalization wizard displays in between a user logging in and them heading to their desired destination.
    After the user completes the form, they are redirected to their original destination by calling paramsBasedRedirect
  in the personalization wizard
  */
  if (personalizationWizardRedirect) {
    if (baasCentralUrl) {
      query.baasCentralUrl = baasCentralUrl;
    }

    if (centralUrl) {
      query.centralUrl = centralUrl;
    }

    query.personalizationWizardRedirect = true;

    const pwRedirectQueryParams = queryString.stringify(query, { sort: false });

    windowLocation.assign(`/v2/${groupId}#/setup/personalization?${pwRedirectQueryParams}`);
    return;
  }

  await paramsBasedRedirect({
    baasCentralUrl,
    baasLegacyUrl,
    centralUrl,
    csrfTime,
    csrfToken,
    query,
    username,
    windowLocation,
  });
};

export function getBaasRootUrl(stitchNextUrl: string, baasCentralUrl: string, baasLegacyUrl?: string) {
  let baasRootUrl = '';

  // Base baasRootUrl off of the domain of the nStitch param.
  // Temporary shim is in place so that this works for both the realm and services.cloud domains.
  if (baasLegacyUrl && stitchNextUrl.startsWith(baasLegacyUrl)) {
    baasRootUrl = baasLegacyUrl;
  } else if (stitchNextUrl.startsWith(baasCentralUrl)) {
    baasRootUrl = baasCentralUrl;
  }

  return baasRootUrl;
}

export const paramsBasedRedirect = async ({
  baasCentralUrl,
  baasLegacyUrl,
  centralUrl,
  csrfTime,
  csrfToken,
  query,
  username = '',
  windowLocation = window.location,
}: ParamsBasedRedirectProps): Promise<void> => {
  const { inviteToken, groupId, orgId } = query;
  const queryParams = queryString.stringify(query, { sort: false });

  // Accept invite token flow redirects to specific group/org
  if (inviteToken) {
    const nextUrl = groupId ? `/v2/${groupId}` : `/v2#/org/${orgId}`;
    windowLocation.assign(nextUrl);
    return;
  }

  const stitchNextUrl = query.nStitch;
  if (stitchNextUrl && isMongodbUrlStr(stitchNextUrl)) {
    const baasRootUrl = getBaasRootUrl(stitchNextUrl, baasCentralUrl, baasLegacyUrl);

    if (baasRootUrl) {
      try {
        updateCsrfHeaders({ csrfTime, csrfToken });
        await authenticateWithBaas(username, baasRootUrl, centralUrl);
        windowLocation.assign(stitchNextUrl);
        return;
      } catch (exception) {
        console.error('Some error happened trying to authenticate with App Services', exception);
      }
    }
  }

  // Marketplace link flow redirects to the Organizations Link Marketplace page
  if (query.isMarketplaceLinkFlow) {
    windowLocation.assign('/v2#/preferences/organizations/link-marketplace');
    return;
  }

  // Go to their logged in home page
  // Use the page level search value, as it might exist before the hash.
  const nextUrl = createUrlFromSearchString(window.location.search || queryParams);
  windowLocation.assign(nextUrl);
};

const buildSearchUrl = (pathname: string, queryParams: object): URL => {
  let cleanedPath = pathname;

  if (isSmartLinkPath(pathname)) {
    const lPattern = '?l=';
    const hPattern = '&h=';

    if (queryParams['h']) {
      cleanedPath += `${hPattern}${queryParams['h']}`;
    }

    const [baseURI, rawQuery] = cleanedPath.split(lPattern);
    const [lQuery, hQuery] = rawQuery.split(hPattern);

    if (hQuery && lQuery) {
      cleanedPath = `${baseURI}${lPattern}${encodeURIComponent(lQuery)}${hPattern}${encodeURIComponent(hQuery)}`;
    } else if (lQuery) {
      cleanedPath = `${baseURI}${lPattern}${encodeURIComponent(rawQuery)}`;
    }
  } else {
    // If not a smart link this should always be a relative path so we parse it with this assumption.
    // If this is an absolute path it was done with accidental or ill intent so we feel safe ignoring
    // this path.
    if (pathname.startsWith('/')) {
      const pathWithoutDupeSlashes = pathname.replace(/\/+/g, '/');
      cleanedPath = window.location.origin + pathWithoutDupeSlashes;
    } else if (isMongodbUrlStr(pathname)) {
      cleanedPath = pathname;
    } else {
      cleanedPath = window.location.origin + '/';
    }
  }

  try {
    const url = new URL(cleanedPath);
    return url;
  } catch {
    return new URL(window.location.origin);
  }
};

const isSmartLinkPath = (pathname: string) => {
  return /https?:\/\/(localhost:8080|.+mongodb\.com.*)\/go/g.test(pathname);
};
