import { BackingCloudProvider, CloudProvider, NonServerlessUSSCloudProvider } from '@packages/types/nds/provider';
import { RegionUsageRestrictions } from '@packages/types/nds/regionUsageRestrictions';

export function isNonServerlessUSSCloudProvider(provider: CloudProvider): provider is NonServerlessUSSCloudProvider {
  return provider !== CloudProvider.SERVERLESS && provider !== CloudProvider.USS;
}

export function isBackingProvider(provider: string): provider is BackingCloudProvider {
  return (
    provider === BackingCloudProvider.AWS ||
    provider === BackingCloudProvider.AZURE ||
    provider === BackingCloudProvider.GCP
  );
}

export function toCloudProvider(provider: BackingCloudProvider): NonServerlessUSSCloudProvider {
  switch (provider) {
    case BackingCloudProvider.AWS:
      return CloudProvider.AWS;
    case BackingCloudProvider.AZURE:
      return CloudProvider.AZURE;
    case BackingCloudProvider.GCP:
      return CloudProvider.GCP;
  }
}

export function toBackingCloudProvider(provider: CloudProvider): BackingCloudProvider {
  switch (provider) {
    case CloudProvider.AWS:
      return BackingCloudProvider.AWS;
    case CloudProvider.AZURE:
      return BackingCloudProvider.AZURE;
    case CloudProvider.GCP:
      return BackingCloudProvider.GCP;
    default:
      throw new Error(`Unknown Cloud Provider: ${provider}`);
  }
}

export interface CloudProviderAvailabilityControls {
  isNdsGovEnabled?: boolean;
  useCNRegionsOnly?: boolean;
  regionUsageRestrictions?: RegionUsageRestrictions;
  isGcpGovAssuredWorkloadsEnabled?: boolean;
}

export function isCloudProviderAvailable(provider: CloudProvider, opts?: CloudProviderAvailabilityControls): boolean {
  const { isNdsGovEnabled, useCNRegionsOnly, regionUsageRestrictions, isGcpGovAssuredWorkloadsEnabled } = opts || {};
  switch (provider) {
    case CloudProvider.AWS:
      return true;
    case CloudProvider.GCP:
      return !!(
        !useCNRegionsOnly &&
        (!isNdsGovEnabled ||
          (isGcpGovAssuredWorkloadsEnabled && regionUsageRestrictions === RegionUsageRestrictions.GOV_REGIONS_ONLY))
      );
    default:
      return !useCNRegionsOnly && !isNdsGovEnabled;
  }
}

export function getAvailableCloudProviders(opts: CloudProviderAvailabilityControls): Array<CloudProvider> {
  return [CloudProvider.AWS, CloudProvider.GCP, CloudProvider.AZURE].filter((c) => isCloudProviderAvailable(c, opts));
}
