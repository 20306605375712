import * as ingestionPipelines from '@packages/common/services/api/nds/ingestionPipelinesApi';

import * as admin from './adminApi';
import * as alerts from './alertsApi';
import * as awsCustomDNS from './awsCustomDNSApi';
import * as churnSurvey from './churnSurveyApi';
import * as clientMetadata from './clientMetadataApi';
import * as cloudProviderAccess from './cloudProviderAccessApi';
import * as cloudProviderCapacity from './cloudProviderCapacityApi';
import * as container from './containerApi';
import * as dataFederationQueryLimits from './dataFederationQueryLimitsApi';
import * as encryptionAtRest from './encryptionAtRestApi';
import * as limits from './limitsApi';
import * as liveImports from './liveImportsApi';
import * as onlineArchive from './onlineArchiveApi';
import * as personalizationWizard from './PersonalizationWizardApi';
import * as privateEndpoint from './privateEndpointApi';
import * as privateNetworkSettings from './privateNetworkSettingsApi';
import * as projectSettings from './projectSettingsApi';
import * as pushBasedLogging from './pushBasedLoggingApi';
import * as serverlessPrivateEndpoint from './serverlessPrivateEndpointApi';
import * as tenantUpgrade from './tenantUpgradeApi';
import auditLog from './auditLogApi';
import backup from './backupApi';
import clusterDescriptions from './clusterDescriptionsApi';
import clusterForm from './clusterFormApi';
import clusterOutageSimulationApi from './clusterOutageSimulationApi';
import dataLakes from './dataLakesApi';
import ftsIndexConfig from './ftsIndexConfigApi';
import geoZoneMap from './geoZoneMapApi';
import groupStatus from './groupStatusApi';
import loadSampleDatasetApi from './loadSampleDatasetApi';
import maintenanceWindows from './maintenanceWindowsApi';
import networkSettings from './networkSettingsApi';
import regionalOutage from './regionalOutageApi';
import roles from './rolesApi';
import serverlessBackup from './serverlessBackupApi';
import serverlessInstanceForm from './serverlessInstanceFormApi';
import serverlessInstances from './serverlessInstancesApi';
import serverlessMergeMigration from './serverlessMergeMigrationApi';
import serverlessSplitMigration from './serverlessSplitMigrationApi';
import streams from './streamsApi';
import tenantBackup from './tenantBackupApi';
import users from './usersApi';
import userSecurity from './userSecurityApi';

export default {
  backup,
  tenantBackup,
  serverlessBackup,
  churnSurvey,
  clusterDescriptions,
  clusterForm,
  geoZoneMap,
  userSecurity,
  networkSettings,
  auditLog,
  users,
  roles,
  encryptionAtRest,
  maintenanceWindows,
  loadSampleDatasetApi,
  dataLakes,
  groupStatus,
  liveImports,
  privateEndpoint,
  container,
  admin,
  ftsIndexConfig,
  awsCustomDNS,
  onlineArchive,
  tenantUpgrade,
  cloudProviderAccess,
  cloudProviderCapacity,
  serverlessInstances,
  serverlessInstanceForm,
  serverlessMergeMigration,
  serverlessSplitMigration,
  streams,
  projectSettings,
  privateNetworkSettings,
  personalizationWizard,
  ingestionPipelines,
  limits,
  serverlessPrivateEndpoint,
  clusterOutageSimulationApi,
  regionalOutage,
  dataFederationQueryLimits,
  pushBasedLogging,
  clientMetadata,
  alerts,
};
