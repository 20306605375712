export interface CostEstimate {
  advancedSecurityDailyCents: number;
  advancedSecurityMonthlyCents: number;
  biConnectorMaxDailyCents: number;
  biConnectorMaxMonthlyCents: number;
  biConnectorSustainedDailyCents: number;
  dailyEstimateCents: number;
  enterpriseAuditingDailyCents: number;
  enterpriseAuditingMonthlyCents: number;
  monthlyEstimateCents: number;
  monthlyFeesEstimateCents: number;
  monthlyAnalyticsEstimateCents: number;
  monthlyBaseEstimateCents: number;
  monthlySearchNodesEstimateCents: number;
  snapshotBackupMonthlyCentsPerGB: number;
}

/** Billing information needed for populating the checkout page.
 * Also seems to match what is returned by the serverless cost estimate API.
 */
export interface PurchaseTransactionBillingEstimate {
  readonly tier1ReadCents?: number | null;
  readonly tier2ReadCents?: number | null;
  readonly tier3ReadCents?: number | null;
  readonly tier4ReadCents?: number | null;
  readonly serverlessWriteCents?: number | null;
  readonly serverlessStorageCents?: number | null;
  readonly serverlessContinuousBackupCents?: number | null;
  readonly monthlyEstimateCents?: number | null;
  readonly hourlyEstimateCents?: number | null;
  readonly dailyEstimateCents?: number | null;
  readonly hourlyBaseEstimateCents?: number | null;
  readonly hourlyAnalyticsEstimateCents?: number | null;
  readonly hourlySearchEstimateCents?: number | null;
  readonly monthlyFeesEstimateCents?: number | null;
}

export enum PartnerType {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}

export enum PartnerLinkStatusCode {
  AVAILABLE = 'AVAILABLE',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  LINK_SUCCESS = 'LINK_SUCCESS',
  CUSTOMER_LINKED = 'CUSTOMER_LINKED',
  FAILED_PAYMENTS = 'FAILED_PAYMENTS',
  IS_LINKED_ORG = 'IS_LINKED_ORG',
  IS_PAYING_ORG = 'IS_PAYING_ORG',
  NOT_ATLAS = 'NOT_ATLAS',
  ORGANIZATION_LINKED = 'ORGANIZATION_LINKED',
  SUBSCRIPTION_INVALID_STATUS = 'SUBSCRIPTION_INVALID_STATUS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface OrganizationLinkStatuses {
  linkable: boolean;
  linkStatusCode: PartnerLinkStatusCode;
  linkStatusDescription: string;
  userRoles: Array<string>;
  organization: {
    id: string;
    name: string;
  };
}

export interface PartnerCustomerLinkOrgStatus {
  partnerType: PartnerType;
  organizationLinkStatuses: Array<OrganizationLinkStatuses>;
}

export interface PartnerCustomerLinkOrgResult {
  success: boolean;
  linkStatusCode: PartnerLinkStatusCode;
  linkStatusDescription: string;
  partnerType: PartnerType;
  organizationId: string;
}

export interface PartnerSubscriptionStatusResult {
  success: boolean;
  subscriptionStatusCode: string;
  subscriptionStatusDescription: string;
  partnerType: string;
  organizationId: string;
}

export interface MarketplaceRegistrationData {
  partnerType: PartnerType;
}

export interface MarketplaceRegistrationCookie {
  partnerType: PartnerType;
}

export enum PartnerSubscriptionStatus {
  SUCCESS = 'success',
  PENDING = 'pending',
  FAILURE = 'failure',
  NOT_LINKING = 'not_linking',
}

export enum PartnerSubscriptionStatusCode {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  NOT_FOUND = 'NOT_FOUND',
}

export enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

/**
 * This is only a partial representation of the fields of UsageSummary;
 * see `UsageSummary.java` for the rest and feel free to add the correct field types to this interface.
 */
export interface UsageSummary {
  subtotalCents: number;
  totalCents: number;
  backupTotalPriceCents: number;
  monitoring: Array<MonitoringSummary>;
  skuServiceSummaries: Array<SkuServiceSummary>;
  support: Array<SupportSummaryItem>;
}

interface MonitoringSummary {
  totalPriceCents: number;
  summaryGroup: string;
}

export interface SkuServiceSummary {
  totalPriceCents: number;
  subtotalPriceCents: number;
  skuService: string;
  items: Array<SkuServiceItem>;
}

interface SkuServiceItem {
  sku: string;
  totalPriceCents: number;
  quantity: number;
  unitPriceDollars: number;
  appendName?: string;
  subDescription?: string;
}

interface SupportSummaryItem {
  planType: string;
  totalPriceCents: number;
}

// Minor naming difference(s) + extra fields for modal compared to PaymentView
export interface MonthlyCommitPaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceUrl: string | null;
  netsuiteInvoiceInternalId: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null; // netsuite info
  unitPrice: number | null;
  totalCents: number | null;
  taxTotalCents: number | null;
  amountDueCents: number | null;
  usageCents: number | null;
  invoicedCents: number | null; // akin to billedUsageCents
  activationCode: string | null;
  creditId: string | null;
  creditType: string | null;
  creditCreated: string | null; // extra info for modal
  creditStartDate: string | null;
  creditEndDate: string | null;
  amountCents: number | null;
  amountRemainingCents: number | null;
  monthlyCommitmentCents: number | null;
  totalBilledUsageCents: number | null;
  totalUsageCents: number | null;
  carryOverCents: number | null;
  isElastic: boolean | null;
  evergreen: boolean | false;
  isRolloverCredit: boolean | false;
}

export interface ProcessedMonthlyCommitPaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceUrl: string | null;
  netsuiteInvoiceInternalId: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null; // netsuite info
  unitPrice: string;
  total: string;
  taxTotal: string;
  amountDue: string;
  usage: string;
  billedUsage: string; // akin to billedUsageCents
  activationCode: string | null;
  creditId: string | null;
  creditType: string | null;
  creditCreated: string | null; // extra info for modal
  creditStartDate: string | null;
  creditEndDate: string | null;
  detailsTotal: string;
  detailsPurchasedFrom: string;
  detailsTotalCreditsUsed: string;
  detailsTotalCreditsBilled: string;
  detailsCreditsRemaining: string;
  detailsCarryOverCredit: string;
  detailsCreditsUsed: string;
  detailsCreditsBilled: string;
  detailsMonthlyCommit: string;
  isElastic: boolean | null;
  evergreen: boolean | false;
  isRolloverCredit: boolean | false;
}

export interface PaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  paymentMethodId: string | null; // paymentMethodId string field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceInternalId: string | null;
  netsuiteInvoiceUrl: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null;
  usageCents: number | null;
  billedUsageCents: number | null;
  amountDueCents: number | null;
  unitPrice: number | null;
  taxTotalCents: number | null;
  totalCents: number | null;
  activationCode: string | null;
  cardLast4: string | null;
  creditId: string | null;
  creditIds: Array<string> | null;
  creditType: string | null;
  cardType: string | null;
  isPulledForward: boolean | null;
  isElastic: boolean | null;
  lastFailedChargeAttempt: string | null;
  processingStartDate: string | null;
  amountBilledInLocalCurrency: number | null;
  evergreen: boolean | false;
  isRolloverCredit: boolean | false;
}

export interface ProcessedPaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  paymentMethodId: string | null; // paymentMethodId string field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceInternalId: string | null;
  netsuiteInvoiceUrl: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null;
  usage: string;
  billedUsage: string;
  amountDue: string;
  unitPrice: string;
  taxTotal: string;
  total: string;
  activationCode: string | null;
  cardLast4: string | null;
  creditId: string | null;
  creditType: string | null;
  cardType: string | null;
  isPulledForward: boolean | null;
  isElastic: boolean | null;
  lastFailedChargeAttempt: string | null;
  processingStartDate: string | null;
  amountBilledInLocalCurrency: number | null;
  evergreen: boolean | false;
  isRolloverCredit: boolean | false;
}

export enum SelfServePaymentMethodType {
  PAYPAL = 'PAYPAL',
  CREDIT_CARD = 'CREDIT_CARD',
  AWS_MARKETPLACE_SELF_SERVE = 'AWS_MARKETPLACE_SELF_SERVE',
  GCP_MARKETPLACE_SELF_SERVE = 'GCP_MARKETPLACE_SELF_SERVE',
  AZURE_MARKETPLACE_SELF_SERVE = 'AZURE_MARKETPLACE_SELF_SERVE',
}

export enum PaymentMethodType {
  CreditCard = 'CREDIT_CARD',
  Paypal = 'PAYPAL',
  Invoice = 'INVOICE',
  MonthlyCommitment = 'MONTHLY_COMMITMENT',
  Check = 'CHECK',
  WireTransfer = 'WIRE_TRANSFER',
  GcpMarketplaceInvoice = 'GCP_MARKETPLACE_INVOICE',
  GcpMarketplaceElasticInvoice = 'GCP_MARKETPLACE_ELASTIC_INVOICE',
  AwsMarketplaceInvoice = 'AWS_MARKETPLACE_INVOICE',
  AwsMarketplaceElasticInvoice = 'AWS_MARKETPLACE_ELASTIC_INVOICE',
  AzureMarketplaceInvoice = 'AZURE_MARKETPLACE_INVOICE',
  AzureMarketplaceElasticInvoice = 'AZURE_MARKETPLACE_ELASTIC_INVOICE',
  AdminOverride = 'ADMIN_OVERRIDE',
  AwsMarketplaceSelfServe = 'AWS_MARKETPLACE_SELF_SERVE',
  AzureMarketplaceSelfServe = 'AZURE_MARKETPLACE_SELF_SERVE',
  GcpMarketplaceSelfServe = 'GCP_MARKETPLACE_SELF_SERVE',
  Prepaid = 'PREPAID',
}

export enum CreditType {
  Generic = 'GENERIC',
  Promotion = 'PROMO',
  Prepaid = 'PREPAID_NDS',
  MonthlyCommitment = 'MONTHLY_COMMITMENT',
  GcpBillingAccount = 'GCP_BILLING_ACCOUNT',
  GcpPrepaid = 'GCP_PREPAID',
  GcpMonthlyCommitment = 'GCP_MONTHLY_COMMITMENT',
  AwsPrepaid = 'AWS_PREPAID',
  AwsMonthlyCommitment = 'AWS_MONTHLY_COMMITMENT',
  AzurePrepaid = 'AZURE_PREPAID',
  AzureMonthlyCommitment = 'AZURE_MONTHLY_COMMITMENT',
  AwsSelfServe = 'AWS_SELF_SERVE',
  AzureSelfServe = 'AZURE_SELF_SERVE',
  GcpSelfServe = 'GCP_SELF_SERVE',
  Rollover = 'ROLLOVER',
  DirectFlexCommit = 'DIRECT_FLEX_COMMIT',
  DirectFlexCommitRollover = 'DIRECT_FLEX_COMMIT_ROLLOVER',
  GcpFlexCommit = 'GCP_FLEX_COMMIT',
  GcpFlexCommitRollover = 'GCP_FLEX_COMMIT_ROLLOVER',
  AwsFlexCommit = 'AWS_FLEX_COMMIT',
  AwsFlexCommitRollover = 'AWS_FLEX_COMMIT_ROLLOVER',
  AzureFlexCommit = 'AZURE_FLEX_COMMIT',
  AzureFlexCommitRollover = 'AZURE_FLEX_COMMIT_ROLLOVER',
}

export interface PaymentMethod {
  id: string;
  type: string;
  created: string;
  status: PaymentMethodStatus;
}

export interface SelfServePaymentMethod extends PaymentMethod {
  type: SelfServePaymentMethodType;
  status: PaymentMethodStatus;
  billingAddress: BillingAddress | null;
  billingAccount: string | null;
}

export interface BillingAddress {
  addrLine1: string;
  addrLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface PayPal extends SelfServePaymentMethod {
  type: SelfServePaymentMethodType.PAYPAL;
  payPalEmailAddress: string;
}

export interface CreditCard extends SelfServePaymentMethod {
  type: SelfServePaymentMethodType.CREDIT_CARD;
  billingAccount: string;
  cardLast4: string;
  deactivated: string | null;
  cardExpiration: string;
  cardType: string;
  created: string;
  nameOnCard: string;
  orgId: string;
  stripeCardId?: string;
  stripeCustomerId?: string;
}

export type TypedSelfServePaymentMethod<MethodType extends SelfServePaymentMethodType> =
  MethodType extends SelfServePaymentMethodType.PAYPAL
    ? PayPal
    : MethodType extends SelfServePaymentMethodType.CREDIT_CARD
      ? CreditCard
      : SelfServePaymentMethod & { type: MethodType };

export interface PartnerSelfServe {
  id: string;
  type: SelfServePaymentMethodType;
  status: PaymentMethodStatus;
}

export interface CompanyAddress {
  companyName?: string | null;
  id?: string | null;
  orgId?: string | null;
  created?: string | null;
  addrLine1: string;
  addrLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  vatNumber?: string | null;
}

export interface Invoice {
  id: string;
  startDate: Date;
  endDate: Date;
  status: string;
  amountBilledCents: bigint;
  subtotalCents: bigint;
  type: string;
  orgId: string;
  orgName: string;
}

export interface PayingOrg {
  payingOrgId: string;
  linkedOrgIds: Array<string>;
}

export enum BillingCrossOrgState {
  PAYING = 'PAYING',
  LINKED = 'LINKED',
  PENDING_UNLINKING = 'PENDING UNLINKING',
  STANDALONE = 'STANDALONE',
}

export interface LinkableOrg {
  orgId: string;
  orgName: string;
  payingOrgId: string;
  payingOrgName: string;
  planType: string;
  isCurrentOrg: boolean;
  isPayingOrg: boolean;
  selected: boolean;
  billingCrossOrgState: BillingCrossOrgState;
  currentUserIsBillingAdmin: boolean;
  currentUserIsOrgMember: boolean;
  currentUserIsPayingOrgMember: boolean;
  hasFailedPayments: boolean;
  hasPaymentsDunningNotificationEligibleInThreeDays: boolean;
  invoiceEndDate: string | null;
  lastLinkedDate: string | null;
  // the following 3 props apply on to orgs that are pending unlinking
  requestedUnlinkDate: string | null;
  unlinkRequestId: string | null;
  activationCodeLastFourDigits: string | null;
}

export interface LinkedOrg {
  orgId: string;
  orgName: string;
  planType: string;
}

export interface ConsultingDepth {
  name: string;
  friendlyName: string;
  units: number;
  duration: string;
  services: Array<string>;
}

export interface Payment {
  id: string;
  amountBilledCents: number;
  amountPaidCents: number;
  paymentMethodId: string;
}

export interface LineItem {
  appId: null | string;
  appName: null | string;
  awsRegionName: null | string;
  azureRegionName: null | string;
  clusterName: null | string;
  clusterUniqueId: null | string;
  configServer: null | string;
  creditId: null | string;
  discountCents: number;
  discountId: null | string;
  endDate: string;
  gcpBase: null | string;
  gcpDest: null | string;
  gcpRegionName: null | string;
  gcpSource: null | string;
  groupId: string;
  id: string;
  invoiceId: string;
  note: null | string;
  percentDiscount: number;
  quantity: number;
  replicaSet: null | string;
  sku: string;
  startDate: string;
  totalPriceCents: number;
  unitPriceDollars: number;
  description: null | string;
  usageOrStartDate: string;
}

export type StripeError = stripe.Error;

export interface BillingMetadata {
  skuInfo: {};
  skuUnitsSingular: {};
  skuUnitsPlural: {};
  summaryGroups: {};
  skuServices: {};
  awsRegionNames: {};
  gcpRegionNames: {};
  azureRegionNames: {};
  planTypes: { [identifier: string]: string };
  flexConsultingDepths: {};
}

export interface Plan {
  startDate?: string | null;
  endDate?: string | null;
  entitlementsDrawdownMinimumCents: number | null;
  entitlementsDrawdownPercentage: number | null;
  hasAtlasSubscriptionUpliftOrMinimum: boolean;
  hasTieredSubscriptionPricing: boolean;
  supportSlaMinutes: number;
  planType: string;
  groupType?: string;
  entitlementsDescription?: string;
  prepaidPlans: Array<PrepaidPlan>;
  freeUntil?: string | null;
  minimumEnrollmentWindow?: number | null;
  elasticInvoicing: boolean;
  elasticInvoicingActivationDate: string | null;
  gcpMarketplace?: {
    entitlementId: string;
    accountId: string;
  };
}

export interface PrepaidPlan {
  activationCode: string;
  startDate: string;
  endDate: string;
  skus?: Array<string>;
  type: string;
  creditId?: string;
}

export interface Group {
  name: string;
  groupType: string;
  numBillableServers: number;
  numCluster: number;
  dataSizeBackedUpBytes: number;
}

export interface Discount {
  startDate: string;
  endDate: string;
  code: string;
  description: string;
  percentDiscount: number;
  elasticInvoicing?: boolean; //this is necessary because discounts are used in credit table, and in credit table we
  // check for elasticInvoicing
}

export interface OrgPreferences {
  shouldSendInvoiceOnlyToBillingEmail?: boolean;
}

/**
 * See PlanBillingDetailsView.java
 */
export interface ReactivationDetails {
  estimatedCharges: string | null;
  planStartDate: string | null;
}

export interface PlanBillingDetails {
  reactivationDetails: ReactivationDetails;
}

export enum SelfServeProductType {
  ATLAS_DEVELOPER = 'ATLAS_DEVELOPER',
  NDS_SELF_SERVE_PRO = 'NDS_SELF_SERVE_PRO',
}

export interface SelfServeProduct {
  type: string;
  planType: string;
  minimumEnrollmentPeriodDays: number | null;
  freeTrialDays: number | null;
  entitlementsDrawdownMinimumCents: number;
  entitlementsDrawdownPercentage: number;
}

export interface Credit {
  id: string;
  orgId: string;
  created: string;
  activationCode?: string;
  reason?: string;
  issuer?: string;
  code?: string;
  amountCents: number;
  amountRemainingCents: number;
  totalBilledCents?: number;
  note: string;
  type: string;
  couponId?: string | null;
  startDate: string;
  endDate: string;
  orgName?: string;
  planType?: string;
  isValidPaymentMethod?: boolean;
  hourlyBillingLimitCents?: number;
  monthlyMinimum?: number;
  isValidToday: boolean;
  elasticInvoicing?: boolean;
  salesforceOpportunityLineItemId?: string;
  salesforceOpportunityLink?: string;
  isEligibleForTieredPricing?: boolean | false;
  eligiblePullForwardDate?: Date | null;
  isRollover?: boolean | false;
}

export enum BillingStatus {
  BILLING = 'BILLING',
  NOT_BILLING = 'NOT_BILLING',
}

export interface BraintreeCustomer {
  orgId: string;
  paymentMethodToken: string;
  payPalEmailAddress: string;
}

export enum RefundType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  FULL_TAX = 'FULL_TAX',
}

// Billing Common Domain Objects
export interface ServiceDescription {
  displayName: string;
  parent?: string;
  displayOrder: number;
}

export interface DateRange {
  start?: Date;
  end?: Date;
}

// CostExplorer domain objects
export interface CostExplorerFilterOptions {
  orgs: ReadonlyArray<CostExplorerOrgFilterOptions>;
  services: ReadonlyArray<ServiceDescription>;
  minStartDate: Date;
  maxStartDate: Date;
}

export interface CostExplorerOrgFilterOptions {
  id: string;
  name?: string;
  projects?: ReadonlyArray<CostExplorerProjectFilterOptions>;
}

export interface CostExplorerProjectFilterOptions {
  id: string;
  name?: string;
  clusters?: ReadonlyArray<CostExplorerClusterFilterOptions>;
}

export interface CostExplorerClusterFilterOptions {
  id: string;
  name?: string;
}

export interface CostExplorerQueryResults {
  usageDetails: ReadonlyArray<CostExplorerQueryResultsUsageDetails>;
}

export interface CostExplorerQueryResultsUsageDetails {
  usageDate: string;
  invoiceId: string;
  organizationName?: string;
  organizationId: string;
  projectName?: string;
  projectId?: string;
  clusterName?: string;
  clusterId?: string;
  service?: string;
  usageAmount: number;
}

export enum CostExplorerQueryGrouping {
  ORGANIZATION = 'organizations',
  PROJECT = 'projects',
  CLUSTER = 'clusters',
  SERVICE = 'services',
}

export interface CostExplorerQuerySelections extends CostExplorerFilterSelections {
  groupBy: CostExplorerQueryGrouping;
}

export interface CostExplorerFilterSelections {
  organizations: ReadonlyArray<string>;
  projects: ReadonlyArray<string>;
  clusters: ReadonlyArray<string>;
  services: ReadonlyArray<string>;
  startDate?: Date;
  endDate?: Date;
  includePartialMatches: boolean;
}

export interface CostExplorerQueryState {
  results: CostExplorerQueryResults;
  selections: CostExplorerQuerySelections;
}

// Usage Details Domain Objects

// Various metadata used to populate the various filters and date pickers on
//  the Invoice Usage Details page.
export interface UsageDetailsFilterOptions {
  projects?: ReadonlyArray<UsageDetailsProjectOption>;
  skuServices?: ReadonlyArray<ServiceDescription>;
  billDates?: DateRange;
  usageDates?: DateRange;
}

// Defines the metadata for a single project to be selected as an option
//   within the project filter dropdown on the Invoice Usage Details
//   screen.  Info used to populate the dropdown.
export interface UsageDetailsProjectOption {
  id: string;
  name?: string;
  clusters?: ReadonlyArray<UsageDetailsClusterOption>;
}

// Defines the metadata for a single cluster to be selected as an option
//   within the cluster filter dropdown on the Invoice Usage Details
//   screen. Info used to populate the dropdown.
export interface UsageDetailsClusterOption {
  id: string;
  name?: string;
}

// Actual user selections from filter dropdowns and date pickers on Invoice
//   Usage Details screen. Also includes the zero cent line items button
//   which toggles whether zero cent line items are displayed.
export interface UsageDetailsFilterSelections {
  groupIds: ReadonlyArray<string>;
  clusters: ReadonlyArray<string>;
  services: ReadonlyArray<string>;
  billDateRange?: DateRange;
  usageDateRange?: DateRange;
  includeZeroCentLineItems?: boolean;
}

// Only 1 sort order can be applied at a time. This will reflect
//  the field being sorted and whether is ascending / descending.
//  Field name reflects client side field name as referenced in the
//  LeafyGreen column / table.
export interface UsageDetailsSortCriterion {
  fieldName: string;
  ascending: boolean;
}

// Info that is sent to the server to indicate how to retrieve the
//  next page based on page number and number of rows in each page.
export interface UsageDetailsPaginationOptions {
  pageNum: number;
  pageSize?: number;
}

// Contains all information bundled together that is needed to
//  reflect a query within usage details. This does not just
//  include how the data is filtered based on user selections, but
//  it also includes how it is sorted and which page is next to be
//  displayed.
export interface UsageDetailsQuery {
  filters: UsageDetailsFilterSelections;
  sortCriterion?: UsageDetailsSortCriterion;
  paginationOptions?: UsageDetailsPaginationOptions;
}

// This is not intended to be a complete representation of a line item
//  but merely a subset of the fields that are actually displayed in the
//  line item table.
export interface UsageDetailsLineItem {
  startDate: Date;
  usageOrStartDate: Date;
  quantity: number;
  description: string;
  sku: string;
  groupId: string;
  unitPriceDollars: number;
  discountId?: string;
  percentDiscount?: number;
  totalPriceCents: number;
  clusterName?: string;
}

// This is returned from the server to indicate which page was
//   just served as well as if there are previous and next pages.
//   This info can be used to adjust how the pagination widget is
//   displayed.
export interface UsageDetailsQueryPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNum: number;
}

// This reflects overall pagination information that is retrieved to
//  indicate how many rows need to be displayed, which can be used
//  to derive the number of pages. This does not need to be regenerated
//  as the user clicks between pages or sorts, only when filter criteria
//  are changed.
export interface UsageDetailsPaginationMetadata {
  rowCount: number;
}

// Reflects the bundled information coming back from the server which
// is used to render the data associated with the usage details widget.
// The line items with the fields needed for that widget as well as what
// page is being displayed are included here.
export interface UsageDetailsQueryResults {
  lineItems: ReadonlyArray<UsageDetailsLineItem>;
  pageInfo: UsageDetailsQueryPageInfo;
}

// Bundles together the query being run and the results of that query for
//  easy storage in Redux.
export interface UsageDetailsQueryState {
  results?: UsageDetailsQueryResults;
  query?: UsageDetailsQuery;
}

export const SalesSoldDirectCreditTypes = new Set([
  CreditType.Prepaid,
  CreditType.MonthlyCommitment,
  CreditType.Rollover,
  CreditType.DirectFlexCommit,
  CreditType.DirectFlexCommitRollover,
]);

export const SalesSoldMarketplaceCreditTypes = new Set([
  CreditType.GcpBillingAccount,
  CreditType.GcpMonthlyCommitment,
  CreditType.GcpPrepaid,
  CreditType.AwsMonthlyCommitment,
  CreditType.AwsPrepaid,
  CreditType.AzurePrepaid,
  CreditType.AzureMonthlyCommitment,
  CreditType.AwsFlexCommit,
  CreditType.AwsFlexCommitRollover,
  CreditType.GcpFlexCommit,
  CreditType.GcpFlexCommitRollover,
  CreditType.AzureFlexCommit,
  CreditType.AzureFlexCommitRollover,
]);

export const SalesSoldCreditTypes = new Set([...SalesSoldDirectCreditTypes, ...SalesSoldMarketplaceCreditTypes]);

export interface PullForwardRequestUpdateResponse {
  isEligibleForPullforward: boolean;
  originalStartDate?: Date;
  pullForwardStartDate?: Date;
}

interface PendingDealActivationDetails {
  pendingDealId: string;
  activationCode: string;
}

export interface PendingUnlinkRequest {
  payingOrgId: string;
  linkedOrgId: string;
  pendingDealDetails: PendingDealActivationDetails;
  lastUpdateTs: Date;
  status: string;
  requestedUnlinkDate: Date;
}

export interface FetchYayPayLinkResponseView {
  link: string;
}
