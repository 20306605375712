import { createSelector } from 'reselect';
import _ from 'underscore';

import {
  CloudProviderAccessAWSAccountDetails,
  CloudProviderAccessAzureAccountDetails,
} from '@packages/types/nds/cloudProviderAccess';
import {
  DataLakeMetrics,
  DataLakeSQLSchemaManagement,
  DataLakeTenant,
  DataSource,
  DataStore,
  Frequency,
  Namespace,
  SchemaStatus,
  SchemaUpdatedSource,
  SchemaWithMetadata,
  StorageConfig,
  StorageValidationResult,
  StorageValidationStatus,
  TenantSQLData,
  TenantSQLScheduledUpdate,
} from '@packages/types/nds/dataLakes';
import { CloudProvider } from '@packages/types/nds/provider';
import { Region } from '@packages/types/nds/region';
import { RegionView } from '@packages/types/nds/replicationSpec';

import { getBaasParamsFromState } from '@packages/redux/common/getBaasParams';

import * as api from '@packages/common/services/api';
import StitchApplication from '@packages/common/models/StitchApplication';
import type { StitchApplicationCollectionInterface } from '@packages/common/models/StitchApplicationCollection';
import applicationsService, { BaasParams } from '@packages/common/services/applicationsService';
import {
  convertTenantForConfigEditorConsumption,
  convertTenantForMMSConsumption,
  convertTenantsForConfigEditorConsumption,
  generateUniqueName,
  mergeStoreToDataSourcesMap,
} from '@packages/common/utils/dataLake';
import { generateDataLakeTenant } from '@packages/common/utils/objectInitializers';
import {
  getValidationResultForJSON,
  getValidationResultForVisualCompatibility,
  getVisualEditorIncompatibleReason,
} from '@packages/common/utils/StorageConfigUtils';

const getEmptyValidationResult = (): StorageValidationResult => ({
  status: StorageValidationStatus.SUCCESS,
  storageConfig: getEmptyStorage(),
});
const getEmptyStorage = (): StorageConfig => ({
  stores: [],
  databases: [],
});
const DEFAULT_BASE_NAME = 'FederatedDatabaseInstance';

export interface State {
  atlasAWSAccountDetails: CloudProviderAccessAWSAccountDetails;
  atlasAzureAccountDetails: CloudProviderAccessAzureAccountDetails;
  dataLakeMetrics: DataLakeMetrics;
  dataLakes: Array<DataLakeTenant>;
  regions: Array<RegionView>;
  isNewDataLake: boolean;
  dataLake: DataLakeTenant;
  dataSourceMap: Map<string, Array<DataSource>>;
  visualEditorDisabledReason: string;
  validationResult: StorageValidationResult;
  dataLakeNameToFetchQueryLimits: string;
  azureProviderRegions: Array<Region>;
  dataLakeSQLSchemaManagement: DataLakeSQLSchemaManagement;
}

export const generateInitialState = (): State => ({
  atlasAWSAccountDetails: {
    atlasAssumedRoleExternalId: '',
    atlasAWSAccountArn: '',
  },
  atlasAzureAccountDetails: {
    atlasAzureAppId: '',
  },
  dataLakeMetrics: {
    totalSuccessfulQueries: 0,
    totalFailedQueries: 0,
    totalDataScanned: 0,
    totalDataReturned: 0,
    averageExecutionTime: 0,
  },
  dataLakes: [],
  regions: [],
  isNewDataLake: false,
  dataLake: generateDataLakeTenant(),
  dataSourceMap: new Map<string, Array<DataSource>>(),
  // TODO: remove
  visualEditorDisabledReason: '',
  validationResult: getEmptyValidationResult(),
  dataLakeNameToFetchQueryLimits: '',
  azureProviderRegions: [],
  dataLakeSQLSchemaManagement: {
    tenantSQLData: {
      schemas: [],
      totalSchemas: 0,
      hasSchemas: false,
    },
    schedule: {
      frequency: Frequency.FREQUENCY_UNSPECIFIED,
    },
  },
});

// helpers
const getGroupId = ({ app }) => app.activeGroupId;

const sortDataLakes = (dataLakes) => dataLakes.sort((q1, q2) => q1.name.localeCompare(q2.name));

export const getDefaultInstanceName = (dataLakes: Array<DataLakeTenant>): string =>
  generateUniqueName(DEFAULT_BASE_NAME, new Set((dataLakes || []).map(({ name }) => name)));

const generateDefaultDataLakeTenant = (groupId: string, defaultInstanceName: string): DataLakeTenant => {
  return generateDataLakeTenant({
    name: defaultInstanceName,
    groupId,
    dataProcessRegion: null,
    storage: {
      stores: [],
      databases: [
        {
          name: 'VirtualDatabase0',
          collections: [
            {
              name: 'VirtualCollection0',
              dataSources: [],
            },
          ],
          views: [],
        },
      ],
    },
  });
};

// actions
const FETCH_DATA_LAKES = 'nds/dataLakes/fetch';
const FETCH_ATLAS_AWS_ACCOUNT_DETAILS = 'nds/dataLakes/fetchAtlasAWSAccountDetails';
const FETCH_ATLAS_AZURE_ACCOUNT_DETAILS = 'nds/dataLakes/fetchAtlasAZUREAccountDetails';
const FETCH_AZURE_PROVIDER_REGIONS = 'nds/dataLakes/fetchAzureProviderRegions';
const FETCH_DATA_LAKES_REGIONS = 'nds/dataLakes/regions/fetch';
const REMOVE_DATA_LAKE = 'nds/dataLakes/remove';
const PATCH_DATA_LAKES = 'nds/dataLakes/patch';
const SET_TARGET_DATA_LAKE = 'nds/dataLakes/setTargetDataLake';
const SET_NEW_TARGET_DATA_LAKE = 'nds/dataLakes/newTargetDataLake';
const INSERT_DATA_LAKE_METRIC = 'nds/dataLakes/setMetrics';
const EDIT_TARGET_DATA_LAKE = 'nds/dataLakes/editTargetDataLake';
const SET_DATA_SOURCES = 'nds/dataLakes/setDataSources';
const SET_DATA_SOURCE_MAP = 'nds/dataLakes/setDataSourceMap';
const ADD_DATA_SOURCE = 'nds/dataLakes/addDataSource';
const REMOVE_DATA_SOURCE = 'nds/dataLakes/removeDataSource';
const REMOVE_ALL_DATA_SOURCES = 'nds/dataLakes/removeAllDataSources';
const SET_VISUAL_EDITOR_DISABLED_REASON = 'nds/dataLakes/setVisualEditorDisabledReason';
const SET_DATA_LAKE_NAME_TO_FETCH_QUERY_LIMITS = 'nds/dataLakes/setDataLakeNameToFetchQueryLimits';
const UPDATE_VALIDATION_RESULT = 'nds/dataLakes/updateValidationResult';
const FETCH_ALL_SCHEMAS = 'nds/dataLakes/fetchAllSchemas';
const PUT_SCHEDULED_UPDATE = 'nds/dataLakes/putScheduledUpdate';
const SET_SCHEMA = 'nds/dataLakes/setSchema';
const CLEAR_SCHEMA = 'nds/dataLakes/clearSchema';

// reducer
export default function dataLakesReducer(state = generateInitialState(), action): State {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA_LAKES: {
      return {
        ...state,
        dataLakes: sortDataLakes(payload),
      };
    }
    case FETCH_DATA_LAKES_REGIONS: {
      return {
        ...state,
        regions: payload,
      };
    }
    case REMOVE_DATA_LAKE: {
      return {
        ...state,
        dataLakes: sortDataLakes(state.dataLakes.filter((q) => q.name !== payload)),
      };
    }
    case PATCH_DATA_LAKES: {
      return {
        ...state,
        dataLakes: sortDataLakes([...state.dataLakes.filter((q) => q.name !== payload.name), payload]),
      };
    }
    case SET_TARGET_DATA_LAKE: {
      const dataLake = payload as DataLakeTenant;
      const { storage = getEmptyStorage() } = dataLake;
      const visualEditorDisabledReason = getVisualEditorIncompatibleReason(storage);
      const validationResult = getValidationResultForVisualCompatibility(storage);
      return {
        ...state,
        dataLake: payload,
        dataSourceMap: mergeStoreToDataSourcesMap(storage),
        isNewDataLake: false,
        visualEditorDisabledReason,
        validationResult,
      };
    }
    case SET_NEW_TARGET_DATA_LAKE: {
      const { groupId } = payload;
      const { dataLakes } = state;
      const defaultInstanceName = getDefaultInstanceName(dataLakes);
      return {
        ...state,
        dataLake: generateDefaultDataLakeTenant(groupId, defaultInstanceName),
        dataSourceMap: new Map(),
        isNewDataLake: true,
        visualEditorDisabledReason: '',
        validationResult: getEmptyValidationResult(),
      };
    }
    case EDIT_TARGET_DATA_LAKE: {
      const dataLake = { ...state.dataLake, ...payload };
      const { storage = getEmptyStorage() } = dataLake;
      const visualEditorDisabledReason = getVisualEditorIncompatibleReason(storage);
      return {
        ...state,
        dataLake,
        visualEditorDisabledReason,
      };
    }
    case SET_DATA_SOURCE_MAP: {
      const storage = payload as StorageConfig;
      return {
        ...state,
        dataSourceMap: mergeStoreToDataSourcesMap(storage, state.dataSourceMap),
      };
    }
    case SET_DATA_SOURCES: {
      const { dataStore, dataSources } = payload;
      const { dataSourceMap } = state;
      dataSourceMap.set(dataStore.name, dataSources);
      return { ...state, dataSourceMap };
    }
    case ADD_DATA_SOURCE: {
      const { dataStore, dataSource } = payload;
      const { dataSourceMap } = state;
      const dataSources = dataSourceMap.get(dataStore.name) || [];
      const newDataSources = dataSources.filter((ds) => !_.isEqual(ds, dataSource)).concat(dataSource);
      dataSourceMap.set(dataStore.name, newDataSources);
      return { ...state, dataSourceMap };
    }
    case REMOVE_DATA_SOURCE: {
      const { dataStore, dataSource } = payload;
      const { dataSourceMap } = state;
      const existingDataSources = dataSourceMap.get(dataStore.name) || [];
      const dataSources = existingDataSources.filter((ds) => !_.isEqual(ds, dataSource));
      dataSourceMap.set(dataStore.name, dataSources);
      return { ...state, dataSourceMap };
    }
    case REMOVE_ALL_DATA_SOURCES: {
      const dataStoreName = payload;
      const { dataSourceMap } = state;
      dataSourceMap.delete(dataStoreName);
      return { ...state, dataSourceMap };
    }
    case INSERT_DATA_LAKE_METRIC: {
      const { tenantName, metrics } = action.payload;
      const dataLakeMetrics = {
        ...state.dataLakeMetrics,
        [tenantName]: metrics,
      };
      return {
        ...state,
        dataLakeMetrics,
      };
    }
    case FETCH_ATLAS_AWS_ACCOUNT_DETAILS: {
      return {
        ...state,
        atlasAWSAccountDetails: payload,
      };
    }
    case FETCH_ATLAS_AZURE_ACCOUNT_DETAILS: {
      return {
        ...state,
        atlasAzureAccountDetails: payload,
      };
    }
    case SET_VISUAL_EDITOR_DISABLED_REASON: {
      return {
        ...state,
        visualEditorDisabledReason: payload,
      };
    }
    case UPDATE_VALIDATION_RESULT: {
      return {
        ...state,
        validationResult: payload,
      };
    }
    case SET_DATA_LAKE_NAME_TO_FETCH_QUERY_LIMITS: {
      return {
        ...state,
        dataLakeNameToFetchQueryLimits: payload,
      };
    }
    case FETCH_AZURE_PROVIDER_REGIONS: {
      return {
        ...state,
        azureProviderRegions: payload,
      };
    }
    case FETCH_ALL_SCHEMAS: {
      return {
        ...state,
        dataLakeSQLSchemaManagement: {
          ...state.dataLakeSQLSchemaManagement,
          tenantSQLData: payload,
        },
      };
    }
    case PUT_SCHEDULED_UPDATE: {
      return {
        ...state,
        dataLakeSQLSchemaManagement: {
          ...state.dataLakeSQLSchemaManagement,
          schedule: payload,
        },
      };
    }

    case SET_SCHEMA: {
      const schemaIndex = state.dataLakeSQLSchemaManagement.tenantSQLData.schemas.findIndex(
        (s) => s.namespace.db === payload.namespace.db && s.namespace.collection === payload.namespace.collection
      );

      if (schemaIndex < 0) {
        return { ...state };
      }

      let updatedArray = state.dataLakeSQLSchemaManagement.tenantSQLData.schemas.slice();
      updatedArray.splice(schemaIndex, 1, payload);

      return {
        ...state,
        dataLakeSQLSchemaManagement: {
          ...state.dataLakeSQLSchemaManagement,
          tenantSQLData: {
            ...state.dataLakeSQLSchemaManagement.tenantSQLData,
            schemas: updatedArray,
          },
        },
      };
    }

    case CLEAR_SCHEMA: {
      const schemaIndex = state.dataLakeSQLSchemaManagement.tenantSQLData.schemas.findIndex(
        (s) => s.namespace.db === payload.db && s.namespace.collection === payload.collection
      );

      if (schemaIndex < 0) {
        return { ...state };
      }

      const schemaToChange: SchemaWithMetadata = {
        namespace: payload,
        source: SchemaUpdatedSource.SOURCE_UNSPECIFIED,
        status: SchemaStatus.EMPTY,
      };

      let updatedArray = state.dataLakeSQLSchemaManagement.tenantSQLData.schemas.slice();
      updatedArray.splice(schemaIndex, 1, schemaToChange);

      return {
        ...state,
        dataLakeSQLSchemaManagement: {
          ...state.dataLakeSQLSchemaManagement,
          tenantSQLData: {
            ...state.dataLakeSQLSchemaManagement.tenantSQLData,
            schemas: updatedArray,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}

// selectors
const dataLakesSelector = (state): State => state.nds.dataLakes;
const dataLakeMetricsSelector = (state) => dataLakesSelector(state).dataLakeMetrics;
const targetDataLakeSelector = (state) => dataLakesSelector(state).dataLake;
// Selectors - SQL Schema Management
const dataLakeTenantSQLDataSelector = (state) => dataLakesSelector(state).dataLakeSQLSchemaManagement.tenantSQLData;
const dataLakeSchemaScheduleSelector = (state) => dataLakesSelector(state).dataLakeSQLSchemaManagement.schedule;
const dataLakeSchemaFrequencySelector = (state) => dataLakeSchemaScheduleSelector(state).frequency;
const schemaWithMetadataSelector = (namespace) =>
  createSelector([dataLakeTenantSQLDataSelector], (schemasWithMetadata) => {
    return schemasWithMetadata.schemas?.find(
      (schema) => `${schema.namespace.db}.${schema.namespace.collection}` === namespace
    );
  });

const getAllNamespacesSelector = createSelector([dataLakeTenantSQLDataSelector], (tenantSQLData) => {
  return tenantSQLData.schemas.map((s) => `${s.namespace.db}.${s.namespace.collection}`);
});

// action creators - sync
const putDataLakes = (dataLakes) => ({ type: FETCH_DATA_LAKES, payload: dataLakes });
const setDataLakesRegions = (regions) => ({ type: FETCH_DATA_LAKES_REGIONS, payload: regions });
const setDataLakeMetric = (tenantName, metrics) => ({
  type: INSERT_DATA_LAKE_METRIC,
  payload: { tenantName, metrics },
});
const removeDataLake = (dataLakeName) => ({ type: REMOVE_DATA_LAKE, payload: dataLakeName });
const patchDataLakes = (dataLake) => ({ type: PATCH_DATA_LAKES, payload: dataLake });
const setTargetDataLake = (dataLake: DataLakeTenant) => ({
  type: SET_TARGET_DATA_LAKE,
  payload: dataLake,
});
// TODO: remove
const disableVisualEditor = (reason: string) => ({
  type: SET_VISUAL_EDITOR_DISABLED_REASON,
  payload: reason,
});
const setNewTargetDataLake = (groupId: string) => ({
  type: SET_NEW_TARGET_DATA_LAKE,
  payload: { groupId },
});
const setDataLakeNameToFetchQueryLimits = (dataLakeName: string) => (dispatch) => {
  dispatch({
    type: SET_DATA_LAKE_NAME_TO_FETCH_QUERY_LIMITS,
    payload: dataLakeName,
  });
};
const setAllSchemasForTenant = (schemas) => (dispatch) => {
  dispatch({
    type: FETCH_ALL_SCHEMAS,
    payload: schemas,
  });
};
const putScheduledUpdate = (schedule: TenantSQLScheduledUpdate) => (dispatch) => {
  dispatch({
    type: PUT_SCHEDULED_UPDATE,
    payload: schedule,
  });
};
const setSchema = (schema: SchemaWithMetadata) => (dispatch) => {
  dispatch({
    type: SET_SCHEMA,
    payload: schema,
  });
};
const clearSchema = (namespace: Namespace) => (dispatch) => {
  dispatch({
    type: CLEAR_SCHEMA,
    payload: namespace,
  });
};

// action creators - async
const loadDataLakes =
  (targetDataLakeName: string | null = null) =>
  (dispatch, getState): Promise<Array<DataLakeTenant>> => {
    const groupId = getGroupId(getState());
    const listPromise = api.nds.dataLakes.getDataLakes(groupId).then((dataLakes) => {
      const convertedDataLakes = convertTenantsForConfigEditorConsumption(dataLakes);
      dispatch(putDataLakes(convertedDataLakes));
      return convertedDataLakes;
    });

    if (targetDataLakeName) {
      const targetPromise = api.nds.dataLakes.getDataLake(groupId, targetDataLakeName).then((targetDataLake) => {
        const convertedDataLake = convertTenantForConfigEditorConsumption(targetDataLake);
        dispatch(setTargetDataLake(convertedDataLake));
      });
      // load target data lake with storage, then return list of data lakes
      return Promise.all([targetPromise, listPromise]).then(([_, dataLakesList]) => dataLakesList);
    }

    return listPromise.then((dataLakes) => {
      dispatch(setNewTargetDataLake(groupId));
      return convertTenantsForConfigEditorConsumption(dataLakes);
    });
  };

const loadSQLSchemas =
  (targetDataLakeName) =>
  (dispatch, getState): Promise<TenantSQLData> => {
    const groupId = getGroupId(getState());
    return api.nds.dataLakes.getAllSchemas(groupId, targetDataLakeName).then((fetchedSchemas) => {
      dispatch(setAllSchemasForTenant(fetchedSchemas));
      return fetchedSchemas;
    });
  };

const loadSQLScheduledUpdate =
  (targetDataLakeName) =>
  (dispatch, getState): Promise<TenantSQLScheduledUpdate> => {
    const groupId = getGroupId(getState());
    return api.nds.dataLakes.getScheduledUpdate(groupId, targetDataLakeName).then((scheduledUpdate) => {
      dispatch(putScheduledUpdate(scheduledUpdate));
      return scheduledUpdate;
    });
  };

const loadDataLakesWithStorage = () => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  return api.nds.dataLakes.getDataLakes(groupId, true).then((dataLakes) => {
    const convertedDataLakes = convertTenantsForConfigEditorConsumption(dataLakes);
    dispatch(putDataLakes(convertedDataLakes));
    return convertedDataLakes;
  });
};

const loadSQLSchemasForTenant = (tenantName) => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  return api.nds.dataLakes.getAllSchemas(groupId, tenantName).then((schemas) => {
    dispatch(setAllSchemasForTenant(schemas));
  });
};

const loadAtlasAWSAccountDetails = () => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  return api.nds.cloudProviderAccess
    .getAtlasAWSAccountDetails(groupId)
    .then((atlasAWSAccountDetails) =>
      dispatch({ type: FETCH_ATLAS_AWS_ACCOUNT_DETAILS, payload: atlasAWSAccountDetails })
    );
};

const loadAtlasAzureAccountDetails = () => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  return api.nds.cloudProviderAccess
    .getAtlasAzureAccountDetails(groupId)
    .then((atlasAzureAccountDetails) =>
      dispatch({ type: FETCH_ATLAS_AZURE_ACCOUNT_DETAILS, payload: atlasAzureAccountDetails })
    );
};

const fetchAzureProviderOptions = () => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  api.nds.clusterForm
    .fetchProviderOptions(CloudProvider.AZURE, groupId)
    .then((azureCloudProviderOptions) =>
      dispatch({ type: FETCH_AZURE_PROVIDER_REGIONS, payload: azureCloudProviderOptions.regions })
    );
};

const loadDataLakesRegions = () => (dispatch, getState) => {
  return api.nds.dataLakes.getDataLakeRegions(getGroupId(getState())).then((regions) => {
    dispatch(setDataLakesRegions(regions));
  });
};

const loadDataLakeMetricsForGroup = (dataLakes) => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  const dataLakeNamesForMetrics = dataLakes.map((tenant) => {
    return api.nds.dataLakes.getDataLakeMetrics(groupId, tenant.name).then((dataLakeMetrics) => {
      dispatch(setDataLakeMetric(tenant.name, dataLakeMetrics));
    });
  });
  return Promise.all(dataLakeNamesForMetrics);
};

const loadDataLakesWithMetrics = () => (dispatch) => {
  return dispatch(loadDataLakesWithStorage()).then((dataLakes) => dispatch(loadDataLakeMetricsForGroup(dataLakes)));
};

const saveDataLake = (dataLakeName, dataLakeTenant, skipRoleValidation) => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  const convertedDataLakeTenant = {
    ...convertTenantForMMSConsumption(dataLakeTenant),
  };
  return api.nds.dataLakes
    .saveDataLake(groupId, { ...convertedDataLakeTenant, name: dataLakeName }, skipRoleValidation)
    .then((dataLake) => {
      const convertedDataLake = convertTenantForConfigEditorConsumption(dataLake);
      dispatch(patchDataLakes(convertedDataLake));
      dispatch(setDataLakeNameToFetchQueryLimits(dataLakeName));
      return convertedDataLake;
    });
};

const updateDataLake = (dataLakeName, dataLakeTenantUpdate, skipRoleValidation) => (dispatch, getState) => {
  const groupId = getGroupId(getState());
  const dataLakeTenant = {
    ...convertTenantForMMSConsumption(dataLakeTenantUpdate),
    name: dataLakeName,
  };
  return api.nds.dataLakes
    .updateDataLake(groupId, dataLakeName, dataLakeTenant, skipRoleValidation)
    .then((response) => {
      const convertedResponse = convertTenantForConfigEditorConsumption(response);
      // Unlike POST requests, PATCH requests should never include sensitive information in the response object.
      dispatch(patchDataLakes(convertedResponse));
      dispatch(setTargetDataLake(convertedResponse));
      return convertedResponse;
    });
};

const unlinkApplications = async (dataSourceName: string, baasParams: BaasParams): Promise<void> => {
  const appCollection = await applicationsService.reloadApplications(baasParams);
  if (!appCollection) {
    return Promise.resolve();
  }

  const apps: StitchApplicationCollectionInterface = appCollection.getApplicationsByDataSourceName(dataSourceName);
  if (!apps || !apps.length) {
    return Promise.resolve();
  }

  const unlinkAppPromises = apps.map((app: typeof StitchApplication) =>
    applicationsService.unlinkApplication({
      ...baasParams,
      appId: app.getAppId(),
      serviceId: app.getServiceId(dataSourceName),
    })
  );

  return Promise.all(unlinkAppPromises).then(applicationsService.reloadApplications(baasParams));
};

const deleteDataLake =
  (dataLakeName, { hasBaasEnabled } = { hasBaasEnabled: false }) =>
  (dispatch, getState) => {
    let unlinkApplicationsPromise = Promise.resolve();
    if (hasBaasEnabled) {
      const baasParams = getBaasParamsFromState(getState());
      unlinkApplicationsPromise = unlinkApplications(dataLakeName, baasParams);
    }

    const groupId = getGroupId(getState());
    return unlinkApplicationsPromise
      .then(() => api.nds.dataLakes.deleteDataLake(groupId, dataLakeName))
      .then((res) => {
        dispatch(removeDataLake(dataLakeName));
        return res;
      });
  };

// TODO: async unnecessary here
const editTargetDataLake = (dataLakeUpdate: Partial<DataLakeTenant>) => (dispatch) => {
  return dispatch({ type: EDIT_TARGET_DATA_LAKE, payload: dataLakeUpdate });
};

// TODO: async unnecessary here
const setDataSourceMap = (storageConfig: StorageConfig) => (dispatch) => {
  return dispatch({ type: SET_DATA_SOURCE_MAP, payload: storageConfig });
};

// TODO: async unnecessary here
const setDataSources = (dataStore: DataStore, dataSources: Array<DataSource>) => (dispatch) => {
  return dispatch({ type: SET_DATA_SOURCES, payload: { dataStore, dataSources } });
};

// TODO: async unnecessary here
const addDataSource = (dataStore: DataStore, dataSource: DataSource) => (dispatch) => {
  return dispatch({ type: ADD_DATA_SOURCE, payload: { dataStore, dataSource } });
};

// TODO: async unnecessary here
const removeDataSource = (dataStore: DataStore, dataSource: DataSource) => (dispatch) => {
  return dispatch({ type: REMOVE_DATA_SOURCE, payload: { dataStore, dataSource } });
};

// TODO: async unnecessary here
const removeAllDataSources = (dataStore: DataStore) => (dispatch) => {
  return dispatch({ type: REMOVE_ALL_DATA_SOURCES, payload: dataStore.name });
};

const validateStorageConfigForJSON =
  (storageConfigJson: string, cloudProvider: CloudProvider) =>
  async (dispatch, getState): Promise<StorageValidationResult> => {
    const groupId = getGroupId(getState());
    const validationResult = await getValidationResultForJSON(
      groupId,
      cloudProvider,
      storageConfigJson,
      api.nds.dataLakes.validateStorageConfig
    );
    dispatch({
      type: UPDATE_VALIDATION_RESULT,
      payload: validationResult,
    });
    return validationResult;
  };

export {
  saveDataLake,
  updateDataLake,
  deleteDataLake,
  editTargetDataLake,
  dataLakesSelector,
  dataLakeMetricsSelector,
  dataLakeTenantSQLDataSelector,
  targetDataLakeSelector,
  schemaWithMetadataSelector,
  getAllNamespacesSelector,
  dataLakeSchemaScheduleSelector,
  dataLakeSchemaFrequencySelector,
  setSchema,
  clearSchema,
  setDataSourceMap,
  setDataSources,
  addDataSource,
  removeDataSource,
  removeAllDataSources,
  loadDataLakes,
  loadDataLakesRegions,
  loadDataLakesWithMetrics,
  loadDataLakesWithStorage,
  loadAtlasAWSAccountDetails,
  loadAtlasAzureAccountDetails,
  loadSQLSchemas,
  loadSQLScheduledUpdate,
  disableVisualEditor,
  setDataLakeNameToFetchQueryLimits,
  validateStorageConfigForJSON,
  fetchAzureProviderOptions,
  setAllSchemasForTenant,
  loadSQLSchemasForTenant,
  putScheduledUpdate,
};
