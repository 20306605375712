import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  postServerlessMergeMigration(groupId: string, donorMTMName: string, recipientMTMName: string) {
    return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${donorMTMName}/${recipientMTMName}/merge`, {
      method: 'POST',
    });
  },
  getServerlessMergeMigrationsByPool(poolId: string) {
    return fetchWrapper(`/nds/serverless/mergeMigrations/pool/${poolId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getServerlessMergeMigration(migrationId: string) {
    return fetchWrapper(`/nds/serverless/mergeMigrations/${migrationId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  postDonorAbortServerlessMergeMigration(migrationId: string) {
    return fetchWrapper(`/admin/nds/mtm/serverless/migrations/merge/${migrationId}/abort`, {
      method: 'POST',
    });
  },
  postCleanUpOrphanTenantDataMergeMigration(migrationId: string, cleanUpActiveOrphans: boolean) {
    return fetchWrapper(`/admin/nds/mtm/serverless/migrations/merge/${migrationId}/cleanUpOrphanTenantData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        cleanUpActiveOrphans,
      }),
    }).then((response) => response.json());
  },
};
