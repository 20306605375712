import { createSelector, createStructuredSelector } from 'reselect';

import { ThemePreference } from '@packages/types/theme';

import * as backboneReduxSync from '@packages/redux/common/backboneReduxSync';

import * as api from '@packages/common/services/api';
import {
  getChartsGroupRole,
  getChartsOrganizationRole,
} from '@packages/common/utils/ChartsSegmentTrackingUserRoleUtil';

const {
  actionTypes: { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE },
} = backboneReduxSync;

const ACKNOWLEDGE_DARK_MODE = 'viewer/ACKNOWLEDGE_DARK_MODE';
const RESET = 'viewer/RESET';
const SET_EMAIL = 'viewer/SET_EMAIL';
const SET_PASSWORD = 'viewer/SET_PASSWORD';
const SET_PHONE_NUMBER = 'viewer/SET_PHONE_NUMBER';
const SET_DATE_FORMAT = 'viewer/SET_DATE_FORMAT';
const SET_TIME_FORMAT = 'viewer/SET_TIME_FORMAT';
const SET_TIME_ZONE = 'viewer/SET_TIME_ZONE';
const SET_ATLAS_GO_TO_DISABLED = 'viewer/SET_ATLAS_GO_TO_DISABLED';
const SET_THEME_PREFERENCE = 'viewer/SET_THEME_PREFERENCE';
const SET_USERNAME = 'viewer/SET_USERNAME';

interface ViewerState {
  primaryEmail?: string;
  phoneNumber?: string;
  dateFormatCode?: string;
  timeFormatCode?: string;
  timeZoneDisplay?: string;
  timeZoneId?: string;
  currentUserCountryCode?: string;
  goToDisabled?: boolean;
  username?: string;
}

const initialState: ViewerState = {};

const backboneToReduxKeyMapping = {
  DATE_FORMAT_CODE: 'dateFormatCode',
  FIRST_NAME: 'firstName',
  HAS_MULTI_FACTOR_AUTH: 'hasMultiFactorAuth',
  HAS_ACCOUNT_MULTI_FACTOR_AUTH: 'hasAccountMultiFactorAuth',
  HAS_ORG_GROUP_CREATOR: 'hasOrgGroupCreator',
  HAS_ORG_USER_ADMIN: 'hasOrgUserAdmin',
  HAS_ORG_BILLING_ADMIN: 'hasOrgBillingAdmin',
  HAS_ORG_BILLING_READ_ONLY: 'hasOrgBillingReadOnly',
  HAS_ORG_READ_ONLY: 'hasOrgReadOnly',
  HAS_ORG_MEMBER: 'hasOrgMember',
  IS_USER_ADMIN: 'isUserAdmin',
  LAST_NAME: 'lastName',
  MOBILE_PHONE_NUMBER: 'phoneNumber',
  PRIMARY_EMAIL: 'primaryEmail',
  TIME_FORMAT_CODE: 'timeFormatCode',
  TIME_ZONE_DISPLAY: 'timeZoneDisplay',
  TIME_ZONE_ID: 'timeZoneId',
  USERNAME: 'username',
  USER_ID: 'id',
  USER_ORG_IDS: 'orgIds',
  IS_AUTOMATION_ADMIN: 'isAutomationAdmin',
  IS_GROUP_ATLAS_ADMIN: 'isGroupAtlasAdmin',
  IS_DATA_ACCESS_ANY: 'isDataAccessAny',
  IS_DATA_PROTECTION_SUPPORT: 'isDataProtectionSupport',
  IS_MONITORING_ADMIN: 'isMonitoringAdmin',
  HAS_GLOBAL_READ_ONLY: 'hasGlobalReadOnly',
  HAS_GLOBAL_OWNER: 'hasGlobalOwner',
  HAS_GLOBAL_ATLAS_ADMIN: 'hasGlobalAtlasAdmin',
  HAS_GLOBAL_BACKUP_COMPLIANCE_POLICY_ADMIN: 'hasGlobalBackupCompliancePolicy',
  HAS_GLOBAL_MONITORING_ADMIN: 'hasGlobalMonitoringAdmin',
  HAS_GLOBAL_AUTOMATION_ADMIN: 'hasGlobalAutomationAdmin',
  HAS_GLOBAL_FEATURE_FLAG_ADMIN: 'hasGlobalFeatureFlagAdmin',
  HAS_GLOBAL_APP_SETTING_ADMIN: 'hasGlobalAppSettingAdmin',
  HAS_GLOBAL_CRON_JOBS_ADMIN: 'hasGlobalCronJobsAdmin',
  HAS_GLOBAL_CHARTS_ADMIN: 'hasGlobalChartsAdmin',
  HAS_GLOBAL_PARTNER_ADMIN: 'hasGlobalPartnerAdmin',
  IS_GROUP_OWNER: 'isGroupOwner',
  IS_ANY_GROUP_ROLE: 'isAnyGroupRole',
  CAN_USE_AUTOMATION_LOG_COLLECTION: 'canUseAutomationLogCollection',
  CURRENT_GROUP_ID: 'ccid',
  CURRENT_ORG_ID: 'currentOrgId',
  MARKETPLACE_REGISTRATION_DATA: 'marketplaceRegistrationData',
  USER_COUNTRY_CODE: 'currentUserCountryCode',
  GO_TO_DISABLED: 'goToDisabled',
  THEME_PREFERENCE: 'themePreference',
  HAS_ACKNOWLEDGED_DARK_MODE: 'hasAcknowledgedDarkMode',

  // For admin
  'appUser.primaryEmail': 'primaryEmail',
  'appUser.username': 'username',
  'userRoles.hasGlobalOwner': 'hasGlobalOwner',
  'userRoles.hasGlobalMonitoringAdmin': 'hasGlobalMonitoringAdmin',
  'userRoles.hasGlobalProactiveSupportAdmin': 'hasGlobalProactiveSupportAdmin',
  'userRoles.hasGlobalUserAdmin': 'hasGlobalUserAdmin',
  'userRoles.hasGlobalReadOnly': 'hasGlobalReadOnly',
  'userRoles.hasGlobalAtlasAdmin': 'hasGlobalAtlasAdmin',
  'userRoles.hasGlobalAtlasOperator': 'hasGlobalAtlasOperator',
  'userRoles.hasGlobalFeatureFlagAdmin': 'hasGlobalFeatureFlagAdmin',
  'userRoles.hasGlobalAppSettingAdmin': 'hasGlobalAppSettingAdmin',
  'userRoles.hasGlobalCronJobsAdmin': 'hasGlobalCronJobsAdmin',
  'userRoles.hasGlobalChartsAdmin': 'hasGlobalChartsAdmin',
  'userRoles.hasGlobalPartnerAdmin': 'hasGlobalPartnerAdmin',
};

// Reducer
export default function viewerReducer(state = initialState, action) {
  switch (action.type) {
    case RESET: {
      // Sanitize property so it's always an array.
      action.payload.orgIds = action.payload.orgIds || [];

      return action.payload;
    }
    case SET_EMAIL: {
      const nextState = { ...state };
      nextState.primaryEmail = action.payload;
      return nextState;
    }
    case SET_PHONE_NUMBER: {
      const nextState = { ...state };
      nextState.phoneNumber = action.payload;
      return nextState;
    }
    case SET_DATE_FORMAT: {
      const nextState = { ...state };
      nextState.dateFormatCode = action.payload;
      return nextState;
    }
    case SET_TIME_FORMAT: {
      const nextState = { ...state };
      nextState.timeFormatCode = action.payload;
      return nextState;
    }
    case SET_TIME_ZONE: {
      const nextState = { ...state };
      const { timeZoneDisplay, timeZoneId } = action.payload;
      nextState.timeZoneDisplay = timeZoneDisplay;
      nextState.timeZoneId = timeZoneId;
      return nextState;
    }
    case SET_ATLAS_GO_TO_DISABLED: {
      const { goToDisabled } = action.payload;
      return {
        ...state,
        goToDisabled,
      };
    }
    case SET_THEME_PREFERENCE: {
      const { setting: themePreference } = action.payload;
      return {
        ...state,
        themePreference,
      };
    }
    case ACKNOWLEDGE_DARK_MODE: {
      return {
        ...state,
        hasAcknowledgedDarkMode: true,
      };
    }
    case SYNC_FROM_BACKBONE: {
      return backboneReduxSync.handleSyncFromBackbone(state, action, backboneToReduxKeyMapping);
    }
    case SYNC_CHANGES_FROM_BACKBONE: {
      return backboneReduxSync.handleSyncChangesFromBackbone(state, action, backboneToReduxKeyMapping);
    }
    case SET_USERNAME: {
      const nextState = { ...state };
      nextState.username = action.payload;
      return nextState;
    }
    default:
      return state;
  }
}

// Selectors

export const getFirstName = (state) => state.viewer.firstName;
export const getLastName = (state) => state.viewer.lastName;
export const getUsername = (state) => state.viewer.username;
export const getPrimaryEmail = (state) => state.viewer.primaryEmail;
export const getOrgIds = (state) => state.viewer.orgIds;
export const getLastAuthAddr = (state) => state.viewer.lastAuthAddr;
export const getTimeZoneDisplay = (state) => state.viewer.timeZoneDisplay;
export const getDateFormatCode = (state): string => state.viewer.dateFormatCode || '';
export const getTimeFormatCode = (state): string => state.viewer.timeFormatCode || 'am-pm';
export const getTimeZoneId = (state): string =>
  state.viewer.timeZoneId || Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getEmployerName = (state) => state.viewer.employer;
export const getPhoneNumber = (state) => state.viewer.phoneNumber || '';
export const hasMultiFactorAuth = (state) => state.viewer.hasMultiFactorAuth;
export const hasAcknowledgedDarkMode = (state) => state.viewer.hasAcknowledgedDarkMode;
export const hasAccountMultiFactorAuth = (state) => state.viewer.hasAccountMultiFactorAuth;
export const canUseAutomationLogCollection = (state) => state.viewer.canUseAutomationLogCollection;
export const getId = (state) => state.viewer.id;
export const getCreated = (state) => state.viewer.created;
export const getLastAuthMethod = (state) => state.viewer.lastAuthMethod;
export const getCurrentOrgId = (state) => state.viewer.currentOrgId;
export const getCurrentProjectId = (state) => state.viewer.ccid;
export const getMarketplaceRegistrationData = (state) => state.viewer.marketplaceRegistrationData;
export const getCurrentUserCountryCode = (state) => state.viewer.currentUserCountryCode;
export const isGoToDisabled = (state) => state.viewer.goToDisabled;
export const getThemePreference = (state) => state.viewer.themePreference;

// Role related selectors
export const isGlobalOwner = (state) => state.viewer.hasGlobalOwner;
export const isGlobalProactiveSupportAdmin = (state) => state.viewer.hasGlobalProactiveSupportAdmin;
export const isGlobalAtlasAdmin = (state) => state.viewer.hasGlobalAtlasAdmin;
export const isGlobalAtlasOperator = (state) => state.viewer.hasGlobalAtlasOperator;
export const isGlobalAutomationAdmin = (state) => state.viewer.hasGlobalAutomationAdmin;
export const isGlobalReadOnly = (state) => state.viewer.hasGlobalReadOnly;
export const isGlobalFeatureFlagAdmin = (state) => state.viewer.hasGlobalFeatureFlagAdmin;
export const isGlobalAppSettingAdmin = (state) => state.viewer.hasGlobalAppSettingAdmin;
export const isGlobalCronJobsAdmin = (state) => state.viewer.hasGlobalCronJobsAdmin;
export const isGlobalPartnerAdmin = (state) => state.viewer.hasGlobalPartnerAdmin;
export const isOrgReadOnly = (state) => state.viewer.hasOrgReadOnly;
export const isOrgMember = (state) => state.viewer.hasOrgMember;
export const isGlobalAccountSuspensionAdmin = (state) => state.viewer.hasGlobalAccountSuspensionAdmin;
export const isGlobalBillingAdmin = (state) => state.viewer.hasGlobalBillingAdmin;
export const isGlobalUserAdmin = (state) => state.viewer.hasGlobalUserAdmin;
export const isGlobalMonitoringAdmin = (state) => state.viewer.hasGlobalMonitoringAdmin;
export const isGlobalChartsAdmin = (state) => state.viewer.hasGlobalChartsAdmin;
export const isOrgOwner = (state) => state.viewer.hasOrgOwner;
export const isOrgBillingAdmin = (state) => state.viewer.hasOrgBillingAdmin;
export const isOrgBillingReadOnly = (state) => state.viewer.hasOrgBillingReadOnly;
export const isOrgGroupCreator = (state) => state.viewer.hasOrgGroupCreator;
export const isOrgUserAdmin = (state) => state.viewer.hasOrgUserAdmin;
export const isGroupOwner = (state) => state.viewer.isGroupOwner;
export const isAnyGroupRole = (state) => state.viewer.isAnyGroupRole;
export const isUserAdmin = (state) => state.viewer.isUserAdmin;
export const isAutomationAdmin = (state) => state.viewer.isAutomationAdmin;
export const isMonitoringAdmin = (state) => state.viewer.isMonitoringAdmin;
export const isGroupAtlasAdmin = (state) => state.viewer.isGroupAtlasAdmin;
export const isDataAccessAny = (state) => state.viewer.isDataAccessAny;
export const isDataProtectionSupport = (state) => state.viewer.hasGlobalBackupCompliancePolicy;
export const isGlobalExperimentAssignmentAdmin = (state) => state.viewer.isGlobalExperimentAssignmentAdmin;
export const isGlobalExperimentOverrideAssignmentAdmin = (state) =>
  state.viewer.isGlobalExperimentOverrideAssignmentAdmin;

const getChartsOrgRoleProps = createStructuredSelector({
  isGlobalChartsAdmin,
  isOrgOwner,
  isOrgGroupCreator,
  isOrgBillingAdmin,
  isOrgBillingReadOnly,
  isOrgReadOnly,
  isOrgMember,
});
const chartsOrgRoleSelector = createSelector(getChartsOrgRoleProps, (props) => getChartsOrganizationRole(props));
const getChartsGroupRoleProps = createStructuredSelector({
  isGlobalChartsAdmin,
  isGroupOwner,
  isDataAccessAny,
  isAnyGroupRole,
});
const chartsGroupRoleSelector = createSelector(getChartsGroupRoleProps, (props) => getChartsGroupRole(props));
export const getChartsUserRoleInfo = createStructuredSelector({
  charts_organization_role: chartsOrgRoleSelector,
  charts_group_role: chartsGroupRoleSelector,
});

// Action Creators

export const resetState = (payload) => ({
  type: RESET,
  payload,
});

const setPassword = (payload) => ({
  type: SET_PASSWORD,
  payload,
});

const setEmail = (payload) => ({
  type: SET_EMAIL,
  payload,
});

const setPhoneNumber = (payload) => ({
  type: SET_PHONE_NUMBER,
  payload,
});

const setDateFormat = (payload) => ({
  type: SET_DATE_FORMAT,
  payload,
});

const setTimeFormat = (payload) => ({
  type: SET_TIME_FORMAT,
  payload,
});

const setTimezone = ({ timeZoneDisplay, timeZoneId }) => ({
  type: SET_TIME_ZONE,
  payload: {
    timeZoneDisplay,
    timeZoneId,
  },
});

const setIsAtlasGoToDisabled = ({ isDisabled }) => ({
  type: SET_ATLAS_GO_TO_DISABLED,
  payload: {
    goToDisabled: isDisabled,
  },
});

const setThemePreference = (setting: ThemePreference) => ({
  type: SET_THEME_PREFERENCE,
  payload: { setting },
});

const setAcknowledgeDarkMode = () => ({
  type: ACKNOWLEDGE_DARK_MODE,
});

export const setUsername = (payload) => ({
  type: SET_USERNAME,
  payload,
});

export const updateEmail = (email) => (dispatch) => {
  return api.user.updateEmail(email).then(({ emailAddress }) => dispatch(setEmail(emailAddress)));
};

export const updatePhoneNumber = (phone) => (dispatch) => {
  return api.user
    .updateMobilePhoneNumber(phone)
    .then(({ mobilePhoneNumber }) => dispatch(setPhoneNumber(mobilePhoneNumber)));
};

export const updatePassword =
  ({ currentPassword, newPassword, confirmPassword }) =>
  (dispatch) => {
    return api.user
      .updatePassword({ currentPassword, newPassword, confirmPassword })
      .then((passwordResponse) => dispatch(setPassword(passwordResponse)));
  };

export const updateDateFormat = (dateFormat) => (dispatch) => {
  return api.settings.updateDateFormat(dateFormat).then(({ value }) => dispatch(setDateFormat(value)));
};

export const updateTimeFormat = (timeFormat) => (dispatch) => {
  return api.settings.updateTimeFormat(timeFormat).then(({ value }) => dispatch(setTimeFormat(value)));
};

export const updateTimeZone =
  ({ canonicalName, displayName, displayWithOffset }) =>
  (dispatch) => {
    return api.settings
      .updateTimeZone({
        timeZoneId: canonicalName,
        displayName,
      })
      .then(() =>
        dispatch(
          setTimezone({
            timeZoneId: canonicalName,
            timeZoneDisplay: displayWithOffset,
          })
        )
      );
  };

export const updateIsGoToDisabled =
  ({ isDisabled }) =>
  (dispatch) => {
    return api.settings
      .updateAtlasGoToSettings({
        isDisabled,
      })
      .then(() =>
        dispatch(
          setIsAtlasGoToDisabled({
            isDisabled,
          })
        )
      );
  };

export const updateThemePreference = (setting: ThemePreference) => (dispatch) => {
  return api.settings.updateThemePreference(setting).then(() => dispatch(setThemePreference(setting)));
};

export const acknowledgeDarkMode = (dispatch) => {
  return api.settings.acknowledgeDarkMode().then(() => dispatch(setAcknowledgeDarkMode()));
};
