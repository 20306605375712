export * from './awsCrossProjectRestore';
export * from './azureExtendedStorage';
export * from './backupJob';
export * from './backupRestoreJob';
export * from './dataProtectionCluster';
export * from './dataProtectionSettings';
export * from './deleteCopiedBackups';
export * from './export';
export * from './exportBucket';

export enum BackupDeliveryType {
  AUTOMATION_PULL = 'AUTOMATION_PULL',
  DIRECT_ATTACH = 'DIRECT_ATTACH',
  MANUAL_DOWNLOAD = 'MANUAL_DOWNLOAD',
  POINT_IN_TIME = 'POINT_IN_TIME',
}

export enum BackupDeliveryMethod {
  AUTOMATION_PULL = 'automated',
  MANUAL_DOWNLOAD = 'download',
  POINT_IN_TIME = 'pointInTime',
}

export enum TenantBackupDeliveryType {
  DOWNLOAD = 'DOWNLOAD',
  RESTORE = 'RESTORE',
}

export enum TenantBackupDeliveryMethod {
  DOWNLOAD = 'DOWNLOAD',
  RESTORE = 'RESTORE',
}

export enum RestoreStrategyType {
  SERVERLESS_STREAMING = 'SERVERLESS_STREAMING',
}
