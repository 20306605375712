import { ServerlessSplitMigration } from '@packages/types/dashboard/ServerlessMTMPoolManager';

import fetchWrapper from '../fetchWrapper';

export default {
  postServerlessSplitMigration(groupId: string, donorMTMName: string, tenantClusterIds: Array<string>) {
    return fetchWrapper(`/nds/serverless/splitMigrations/${groupId}/${donorMTMName}`, {
      method: 'POST',
      body: JSON.stringify(tenantClusterIds),
    });
  },
  getServerlessSplitMigration(splitMigrationId: string): Promise<ServerlessSplitMigration> {
    return fetchWrapper(`/nds/serverless/splitMigrations/${splitMigrationId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getServerlessSplitMigrationsByPool(poolId: string): Promise<Array<ServerlessSplitMigration>> {
    return fetchWrapper(`/nds/serverless/splitMigrations/pool/${poolId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
